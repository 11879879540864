import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  ResponsiveContainer, PieChart, Pie, Cell, Legend,
} from 'recharts';

class NWDonutChart extends PureComponent {
  render() {
    const {
      data, containerWidth, containerHeight, innerRadius, outerRadius, cx, cy, colors, paddingAngle, dataKey, isLegend, customLegend,
    } = this.props;
    return (
      <ResponsiveContainer width={containerWidth} height={containerHeight}>
        <PieChart
          margin={{
            top: 1, left: 1, right: 1, bottom: 1,
          }}
        >
          <Pie
            blendStroke
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            paddingAngle={paddingAngle}
            dataKey={dataKey}
            startAngle={-270}
          >
            {
                data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />)
              }
          </Pie>
          {isLegend ? (
            <Legend
              payload={customLegend}
              layout="vertical"
              verticalAlign="middle"
              align="center"
            />
          ) : <></>}
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

NWDonutChart.propTypes = {
  // The source data which each element is an object.
  data: PropTypes.arrayOf(PropTypes.object),
  // The width of chart container.
  containerWidth: PropTypes.string,
  // The height of chart container.
  containerHeight: PropTypes.string,
  // The inner radius of all the sectors.
  innerRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // The outer radius of all the sectors.
  outerRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // The x-coordinate of center. If set a percentage.
  cx: PropTypes.string,
  // The y-coordinate of center. If set a percentage.
  cy: PropTypes.string,
  // The angle between two sectors.
  paddingAngle: PropTypes.number,
  // The color for each sector
  colors: PropTypes.arrayOf(PropTypes.string),
  // The key of each sector's value.
  dataKey: PropTypes.string,
  // display legend or not
  isLegend: PropTypes.bool,
  // The source data of the content to be displayed in the legend, usually calculated internally.
  customLegend: PropTypes.arrayOf(PropTypes.object),
};

NWDonutChart.defaultProps = {
  data: [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
  ],
  containerWidth: '100%',
  containerHeight: '100%',
  innerRadius: '80%',
  outerRadius: '100%',
  cx: '50%',
  cy: '50%',
  paddingAngle: 0,
  colors: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'],
  dataKey: 'value',
  isLegend: false,
};

export default NWDonutChart;
