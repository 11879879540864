/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckOn from 'assets/icons/checkOn.png';
import CheckOff from 'assets/icons/checkOff.png';
import './Agreement.scss';

class Agreement extends Component {
    state = {
      isCheck: false,
    }

    handleCheck = () => {
      const { isCheck } = this.state;
      this.setState({ isCheck: !isCheck });
      this.props.onCheckAgree(!isCheck);
    }

    render() {
      const { agreement, prospectus } = this.props;
      const { isCheck } = this.state;
      return (
        <section className="agreement">
          <article>
            <div className="agreement__checkbox" onClick={this.handleCheck}>
              {isCheck
                ? <img src={CheckOn} alt="" />
                : <img src={CheckOff} alt="" />}
            </div>
            <div className="agreement__text">
              <p onClick={this.handleCheck}>
                {agreement}
              </p>

              <h4>{prospectus}</h4>
            </div>
          </article>
        </section>
      );
    }
}

Agreement.propTypes = {
  agreement: PropTypes.node,
  prospectus: PropTypes.string,
  onCheckAgree: PropTypes.func,
};

Agreement.defaultProps = {
  agreement: <></>,
  prospectus: '',
  onCheckAgree: () => {},
};

export default Agreement;
