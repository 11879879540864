/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import {
  ProgressBar, PercentageBox, Card, CardHeader, Button,
} from 'components';
import iconEducateOn from 'assets/icons/iconEducateOn.png';
import iconRetireOn from 'assets/icons/iconRetireOn.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './MyGoals.scss';
import { PATH } from 'shared/constant';

const MyGoals = ({ intl, history }) => {
  const data = [
    { title: intl.formatMessage({ id: 'portfolio.equities' }), percent: 55, color: '#FCD53B' },
    { title: intl.formatMessage({ id: 'portfolio.fixed_income' }), percent: 22, color: '#F45F32' },
    { title: intl.formatMessage({ id: 'portfolio.money_market' }), percent: 23, color: '#FCA33B' },
  ];

  return (
    <div className="nw-my-goals">
      <div className="nw-my-goals__child" onClick={() => history.push(PATH.GOAL_DETAIL)}>
        <Card
          header={(
            <CardHeader
              icon={<img src={iconEducateOn} alt="icon" />}
              title={intl.formatMessage({ id: 'myGoals.putraEducation' })}
              subTitle="Rp. 520,000,000"
              desc={(
                <Fragment>
                  <b>+ Rp. 678,000</b>
                  <span>(&uarr; 4.06%)</span>
                </Fragment>
                      )}
              button={<FontAwesomeIcon icon="chevron-right" />}
            />
              )}
        >
          <ProgressBar percent={86} description={intl.formatMessage({ id: 'myGoals.putraEducationProgress' })} />
          <div className="nw-display--flex">
            {data && data.map(item => (
              <PercentageBox
                title={item.title}
                percent={item.percent}
                color={item.color}
                key={item.title}
              />
            ))}
          </div>
        </Card>
      </div>

      <div className="nw-my-goals__child" onClick={() => history.push(PATH.GOAL_DETAIL)}>
        <Card
          header={(
            <CardHeader
              icon={<img src={iconRetireOn} alt="icon" />}
              title={intl.formatMessage({ id: 'myGoals.buildMyRetirement' })}
              subTitle="Rp, 8,000,000,000"
              desc={(
                <Fragment>
                  <b>+ Rp. 678,000</b>
                  <span>(&uarr; 4.06%)</span>
                </Fragment>
                        )}
              button={<FontAwesomeIcon icon="chevron-right" />}
            />
                )}
        >
          <ProgressBar percent={45} description={intl.formatMessage({ id: 'myGoals.buildMyRetirementProgress' })} isWarning />
          <div className="nw-display--flex">
            {data && data.map(item => (
              <PercentageBox
                title={item.title}
                percent={item.percent}
                color={item.color}
                key={item.title}
              />
            ))}
          </div>
        </Card>
      </div>
      <Button value={intl.formatMessage({ id: 'myGoals.setANewGoal' })} onButtonClick={() => history.push(PATH.GOAL)} />
    </div>
  );
};

export default injectIntl(MyGoals);
