import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PATH } from 'shared/constant';
import {
  LayoutWithNavbar, Navbar, SubTitle, InvestmentAnalysis,
} from 'components';


class DeviationAnalysis extends Component {
    onBackButton = () => {
      this.props.history.goBack();
    }

    render() {
      const { intl } = this.props;
      return (
        <LayoutWithNavbar
          header={(
            <Navbar
              title={intl.formatMessage({ id: 'portfolio.deviation_analysis' })}
              leftButton={<FontAwesomeIcon icon="chevron-left" />}
              onClickLeft={this.onBackButton}
            />
              )}
        >
          <SubTitle label="Moderate (Score 67)" noTopSpace linkUrl={PATH.RISK_PROFILING} />
          <InvestmentAnalysis
            subTitle={intl.formatMessage({ id: 'portfolio.equities' })}
            total="Rp. 43,411,633,296"
            current={55}
            target={30}
            isPortfolio
            color="#FCD53B"
          />
          <InvestmentAnalysis
            subTitle={intl.formatMessage({ id: 'portfolio.fixed_income' })}
            total="Rp. 23,661,336,935"
            current={22}
            target={50}
            isPortfolio
            color="#F45F32"
          />
          <InvestmentAnalysis
            subTitle={intl.formatMessage({ id: 'portfolio.money_market' })}
            total="Rp. 25,572,791,241"
            current={23}
            target={20}
            isPortfolio
            color="#FCA33B"
          />
        </LayoutWithNavbar>
      );
    }
}

export default injectIntl(DeviationAnalysis);
