import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import './ActionModal.scss';

const ActionModal = ({
  visible, title, children, onCancel,
}) => {
  const modalClass = classNames({
    'action-modal--open': visible,
  });

  return (
    <div className={`modal action-modal ${modalClass}`}>
      <div className="action-modal__body">
        <div className="action-modal__top">
          <div className="action-modal__title">{title}</div>
          <div className="action-modal__content">{children}</div>
        </div>
        <button type="button" className="action-modal__cancel" onClick={onCancel}>
          <FormattedMessage id="transaction.cancel" />
        </button>
      </div>
    </div>
  );
};

ActionModal.propTypes = {
  /** modal visible */
  visible: PropTypes.bool,
  /** modal title */
  title: PropTypes.string,
  /** modal content */
  children: PropTypes.node,
  /** modal cancel action */
  onCancel: PropTypes.func,
};

ActionModal.defaultProps = {
  visible: false,
  title: '',
  children: null,
  onCancel: () => {},
};

export default ActionModal;
