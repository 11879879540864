import { ACTION_TYPE } from '../actions/actionTypes';

const riskProfile = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.RISK_PROFILE_QUESTION_PENDING:
      return {
        ...state,
        getRiskQuestionPending: true,
        getRiskQuestionSuccess: false,
        getRiskQuestionFailure: false,
        getRiskQuestionError: '',
        questions: null,
      };
    case ACTION_TYPE.RISK_PROFILE_QUESTION_SUCCESS:
      return {
        ...state,
        getRiskQuestionPending: false,
        getRiskQuestionSuccess: true,
        getRiskQuestionFailure: false,
        getRiskQuestionError: '',
        questions: action.questions,
      };
    case ACTION_TYPE.RISK_PROFILE_QUESTION_FAILURE:
      return {
        ...state,
        getRiskQuestionPending: false,
        getRiskQuestionSuccess: false,
        getRiskQuestionFailure: true,
        getRiskQuestionError: action.error,
        questions: null,
      };
    case ACTION_TYPE.RISK_PROFILE_SUBMIT_ANSWER_PENDING:
      return {
        ...state,
        submitAnswerPending: true,
        submitAnswerSuccess: false,
        submitAnswerFailure: false,
        submitAnswerError: '',
        profile: null,
        profileDesc: null,
        score: null,
      };
    case ACTION_TYPE.RISK_PROFILE_SUBMIT_ANSWER_SUCCESS:
      return {
        ...state,
        submitAnswerPending: false,
        submitAnswerSuccess: true,
        submitAnswerFailure: false,
        submitAnswerError: '',
        profile: action.payload.profile,
        profileDesc: action.payload.details,
        score: action.payload.score,
      };
    case ACTION_TYPE.RISK_PROFILE_SUBMIT_ANSWER_FAILURE:
      return {
        ...state,
        submitAnswerPending: false,
        submitAnswerSuccess: false,
        submitAnswerFailure: true,
        submitAnswerError: action.error,
        profile: null,
        profileDesc: null,
        score: null,
      };
    default:
      return state;
  }
};

export default riskProfile;
