import React from 'react';
import PropTypes from 'prop-types';
import './TransactionList.scss';

import TransactionItem from './TransactionItem';
import Card from '../Card/Card';

const TransactionList = ({ data, header }) => (
  <Card header={header}>
    <div className="nw-transaction">
      {data.map((value, key) => (
        <div className="nw-transaction__list" key={key}>
          <TransactionItem
            title={value.unit}
            time={value.time}
            numbers={value.value}
            type={value.type}
            status={value.status}
          />
        </div>
      ))}
    </div>
  </Card>
);

TransactionList.propTypes = {
  // The source data which each is an object
  data: PropTypes.arrayOf(PropTypes.object),
};

TransactionList.defaultProps = {
  data: [{
    unit: '120 Units',
    value: 'Rp. 6,187,822,180',
    profit: '↑ 4.06% (Rp. 3,999,123)',
    time: '10/03/2019',
    type: 'buy',
  }, {
    unit: '60 Units',
    value: 'Rp. 3,093,911,090',
    profit: '↑ 2.72% (Rp. 2,790,654)',
    time: '5/08/2018',
    type: 'buy',
  }, {
    unit: '60 Units',
    value: 'Rp. 3,093,911,090',
    profit: '↑ 2.06% (Rp. 2,123,346)',
    time: '12/06/2018',
    type: 'buy',
  }],
};

export default TransactionList;
