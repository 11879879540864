import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  Card, Navbar, LayoutWithNavbar, Button, InputBox,
} from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { PATH } from 'shared/constant';
import './PaymentCard.scss';

class PaymentCard extends Component {
    onBackButton = () => {
      this.props.history.goBack();
    }

    render() {
      const { intl } = this.props;
      return (
        <LayoutWithNavbar
          header={(
            <Navbar
              title={intl.formatMessage({ id: 'payment.selectDebitCard' })}
              leftButton={<FontAwesomeIcon icon="chevron-left" />}
              onClickLeft={this.onBackButton}
            />
                )}
          className="nw-page-payment"
        >
          <Card header={intl.formatMessage({ id: 'payment.linkaNewCard' })}>
            <InputBox
              title={intl.formatMessage({ id: 'payment.cardNumber' })}
              value="4567 5433 8856 9000"
              customClass="nw-input-box__input--card"
            />
            <div className="nw-debit-card">
              <InputBox
                title={intl.formatMessage({ id: 'payment.expiryDate' })}
                placeholder="Enter"
              />
              <InputBox
                title={intl.formatMessage({ id: 'payment.CVV' })}
                placeholder="Enter"
              />
            </div>
          </Card>
          <Button
            isSecondary
            value={intl.formatMessage({ id: 'payment.continue' })}
            onButtonClick={this.onContinueButton}
          />
        </LayoutWithNavbar>
      );
    }
}

export default injectIntl(PaymentCard);
