import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  ResponsiveContainer, LineChart, Line, XAxis, Tooltip, Legend, YAxis,
} from 'recharts';

import CustomTooltip from '../NWTooltip/NWTooltip';

class NWLineChart extends PureComponent {
  render() {
    const {
      data, listKey, containerHeight, containerWidth, customLegend, activeDot, iconType, isLegend, customTooltipValue, customTooltipLabel,
    } = this.props;
    return (
      <ResponsiveContainer width={containerWidth} height={containerHeight}>
        <LineChart
          data={data}
        >
          <XAxis
            dataKey="name"
            interval="preserveStartEnd"
            minTickGap={0}
            tickLine={false}
            axisLine={false}
            tickMargin={15}
          />
          <YAxis hide />
          <Tooltip content={<CustomTooltip />} customValue={customTooltipValue} customLabel={customTooltipLabel} />
          { isLegend && <Legend payload={customLegend} iconType={iconType} iconSize={16} wrapperStyle={{ bottom: -20 }} /> }
          {(listKey || []).map((value, key) => (
            <Line
              key={key}
              type="linear"
              dot={{ strokeWidth: 0, fill: 'none' }}
              strokeWidth={3}
              activeDot={activeDot}
              dataKey={value.dataKey}
              stroke={value.stroke}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

NWLineChart.propTypes = {
  // The source data which each element is an object.
  data: PropTypes.arrayOf(PropTypes.object),
  // The number of line (including stroke colors and data key) which will render on the chart
  listKey: PropTypes.arrayOf(PropTypes.object),
  // The width of chart container.
  containerWidth: PropTypes.string,
  // The height of chart container.
  containerHeight: PropTypes.string,
  // The source data of the content to be displayed in the legend, usually calculated internally.
  customLegend: PropTypes.arrayOf(PropTypes.object),
  // The active dot is shown when a user enters a line chart and this chart has tooltip.
  activeDot: PropTypes.objectOf(PropTypes.string),
  // icon type in legend
  iconType: PropTypes.string,
  // show legend
  isLegend: PropTypes.bool,
  // Custom value for Tool tip
  customTooltipLabel: PropTypes.string,
  // Custom label for Tool tip
  customTooltipValue: PropTypes.objectOf(PropTypes.string),
};

NWLineChart.defaultProps = {
  data: [
    {
      name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
    },
    {
      name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    },
    {
      name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
    },
    {
      name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    },
    {
      name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
    },
    {
      name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    },
    {
      name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    },
  ],
  listKey: [{
    stroke: '#8884d8',
    dataKey: 'pv',
  }, {
    stroke: '#82ca9d',
    dataKey: 'uv',
  }],
  containerWidth: '100%',
  containerHeight: '100%',
  activeDot: {},
  iconType: 'circle',
  isLegend: true,
  customTooltipLabel: '25/07/2019',
  customTooltipValue: {
    label: 'Value: 18.6119',
    value: '(↑ 6.04%)',
  },
};

export default NWLineChart;
