import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Accordion.scss';

const Accordion = ({
  category, title, description, isOpen, onAccordionToggle,
}) => {
  const accordionClass = classNames({
    'nw-accordion--open': isOpen,
    'nw-accordion': true,
  });

  return (
    <div className={accordionClass}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/no-static-element-interactions */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={onAccordionToggle}>
        <div className="nw-accordion__top">
          <div className="nw-accordion__category">{category}</div>
          <FontAwesomeIcon icon="chevron-down" />
        </div>
        <div className="nw-accordion__title">{title}</div>
      </div>
      <div className="nw-accordion__desc">{description}</div>
    </div>
  );
};

Accordion.propTypes = {
  /** accordion category */
  category: PropTypes.string,
  /** accordion title */
  title: PropTypes.string,
  /** accordion description */
  description: PropTypes.string,
  /** accordion open state */
  isOpen: PropTypes.bool,
  /** accordion toggle action */
  onAccordionToggle: PropTypes.func,
};

Accordion.defaultProps = {
  category: '',
  title: '',
  description: '',
  isOpen: false,
  onAccordionToggle: () => {},
};

export default Accordion;
