import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';

const FilterButton = ({ filterList, defaultActive, onButtonClick }) => {
  const [active, setState] = useState(defaultActive);
  const setActive = (index) => {
    setState(index);
  };

  const getClass = (index) => {
    if (index === active) {
      return 'nw-button--filter--active';
    }
    return '';
  };

  const onFilterClick = (value, key) => {
    onButtonClick(value);
    setActive(key + 1);
  };

  return (
    <>
      <div className="nw-line-chart-filter__filter-area">
        {filterList.map((value, key) => (
          <div key={key} className="nw-line-chart-filter__filter-area__btn">
            <Button
              customClassName={getClass(key + 1)}
              id={value}
              isFilter
              value={value}
              onButtonClick={() => onFilterClick(value, key)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

FilterButton.propTypes = {
  // The list of filter
  filterList: PropTypes.arrayOf(PropTypes.string),
  // The default active filter button
  defaultActive: PropTypes.number,
  // The click event of filter button
  onButtonClick: PropTypes.func,
};

FilterButton.defaultProps = {
  filterList: ['3m', '6m', '12m', '3y', '5y'],
  defaultActive: 3,
  onButtonClick: () => {},
};


export default FilterButton;
