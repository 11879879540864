import { toast } from 'react-toastify';

const displayErrorMessage = (message) => {
  toast.error(message, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const translateProfileDescription = (desc) => {
  switch (desc) {
    case 'riskProfileDetailsModerate':
      return 'riskProfile.moderate_description';
    case 'riskProfileDetailsConservative':
      return 'riskProfile.conservative_description';
    case 'riskProfileDetailsAggressive':
      return 'riskProfile.aggressive_description';
    default:
      return '';
  }
};

const numberWithCommas = (x) => {
  const parts = x.toString().replace(/,/g, '').split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

const isNumeric = (value) => {
  const regex = new RegExp(/^\d+$/);
  return regex.test(value);
};

const dismissAllToast = () => toast.dismiss();

const utilitiesService = {
  displayErrorMessage,
  translateProfileDescription,
  numberWithCommas,
  isNumeric,
  dismissAllToast,
};

export default utilitiesService;
