import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import {
  Homepage,
  Catalogue,
  PortfolioDetails,
  DeviationAnalysis,
  Position,
  TransactionHistory,
  RiskProfiling,
  RiskQuestions,
  FundSummaryPage,
  LoginPage,
  Goal,
  FundFactsheet,
  FAQ,
  OTP,
  GoalDetail,
  BuyFund,
  Payment,
  PaymentCard,
  PaymentConfirmation,
  PaymentComplete,
  SellFund,
  SellConfirmation,
  OrderReceived,
} from 'pages';
import { PATH } from 'shared/constant';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const Routes = ({ location, history }) => (
  <TransitionGroup>
    <CSSTransition
      className="transition-group"
      key={location.key}
      timeout={{ enter: 300, exit: 300 }}
      classNames={history.action === 'PUSH' ? 'forward' : 'backward'}
      onEnter={() => setTimeout(() => window.scrollTo(0, 0), 0)}
    >
      <section>
        <Switch location={location}>
          <Route
            path={PATH.HOME}
            exact
            component={Homepage}
          />
          <Route
            path={PATH.CATALOGUE}
            component={Catalogue}
          />
          <Route
            path={`${PATH.FUND_FACTSHEET}/:id`}
            component={FundFactsheet}
          />
          <Route
            path={PATH.PORTFOLIO_DETAIL}
            component={PortfolioDetails}
          />
          <Route
            path={PATH.DEVIATION_ANALYSIS}
            component={DeviationAnalysis}
          />
          <Route
            path={`${PATH.POSITION}/:id`}
            component={Position}
          />
          <Route
            path={PATH.TRANSACTION_HISTORY}
            component={TransactionHistory}
          />
          <Route
            path={PATH.RISK_QUESTIONS}
            component={RiskQuestions}
          />
          <Route
            path={PATH.RISK_PROFILING}
            component={RiskProfiling}
            exact
          />
          <Route
            path={PATH.CATALOGUE_INTERNAL}
            component={FundSummaryPage}
          />
          <Route
            path={PATH.GOAL}
            component={Goal}
          />
          <Route
            path={PATH.GOAL_DETAIL}
            component={GoalDetail}
          />
          <Route
            path={PATH.FAQ}
            component={FAQ}
          />
          <Route
            path={PATH.OTP}
            component={OTP}
          />
          <Route
            path={PATH.BUY_FUND}
            component={BuyFund}
          />
          <Route
            path={PATH.PAYMENT}
            component={Payment}
          />
          <Route
            path={PATH.PAYMENT_CARD}
            component={PaymentCard}
          />
          <Route
            path={PATH.PAYMENT_CONFIRM}
            component={PaymentConfirmation}
          />
          <Route
            path={PATH.PAYMENT_COMPLETE}
            component={PaymentComplete}
          />
          <Route
            path={PATH.SELL_CONFIRMATION}
            component={SellConfirmation}
          />
          <Route
            path={PATH.SELL_FUND}
            component={SellFund}
            extract
          />
          <Route
            path={PATH.ORDER_RECEIVED}
            component={OrderReceived}
          />
          <Route
            path={PATH.LOGIN}
            component={LoginPage}
            extract
          />
        </Switch>
      </section>
    </CSSTransition>
  </TransitionGroup>
);

export default withRouter(Routes);
