import React, { Children, cloneElement, useState } from 'react';

const AccordionGroup = ({ children }) => {
  const [currentAccordion, setCurrentAccordion] = useState(null);

  const handleToggle = (e) => {
    setCurrentAccordion(currentAccordion === e ? null : e);
  };

  return (
    <div className="nw-accordion-group">
      {Children.map(children, (child, i) => cloneElement(child, { isOpen: currentAccordion === i, onAccordionToggle: () => handleToggle(i) }))}
    </div>
  );
};

export default AccordionGroup;
