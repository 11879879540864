import React from 'react';
import PropTypes from 'prop-types';
import { NWDonutChart } from 'components';
import './PercentageBox.scss';

const PercentageBox = ({ title, percent, color }) => (
  <div className="nw-percent-box">
    <div className="nw-percent-box__donut">
      <NWDonutChart data={[{ value: percent }, { value: 100 - percent }]} colors={[color, 'rgba(252,213,59,0.2)']} />
    </div>
    <div className="nw-percent-box__text">
      <div className="nw-percent-box__title">{title}</div>
      <div className="nw-percent-box__percent">{`${percent}%`}</div>
    </div>
  </div>
);

PercentageBox.propTypes = {
  /** box title */
  title: PropTypes.string,
  /** percent value */
  percent: PropTypes.number,
  /** percent color */
  color: PropTypes.string,
};

PercentageBox.defaultProps = {
  title: '',
  percent: 0,
  color: '',
};

export default PercentageBox;
