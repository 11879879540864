import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LayoutWithNavbar, Navbar, Card, CardHeader, ProgressBar, PercentageBox, NWLineChartWithFilter, SubTitle, ItemList, Button,
} from 'components';
import iconEducateOn from 'assets/icons/iconEducateOn.png';
import { PATH } from 'shared/constant';
import './GoalDetail.scss';

class GoalDetail extends Component {
    onBackButton = () => {
      this.props.history.goBack();
    };

    render() {
      const { intl } = this.props;
      const dataGoal = [
        { title: intl.formatMessage({ id: 'portfolio.equities' }), percent: 55, color: '#FCD53B' },
        { title: intl.formatMessage({ id: 'portfolio.fixed_income' }), percent: 22, color: '#F45F32' },
        { title: intl.formatMessage({ id: 'portfolio.money_market' }), percent: 23, color: '#FCA33B' },
      ];
      const dataChart = [
        {
          name: 'J', pv: 400,
        },
        {
          name: 'F', pv: 300,
        },
        {
          name: 'M', pv: 500,
        },
        {
          name: 'A', pv: 100,
        },
        {
          name: 'M', pv: 600,
        },
        {
          name: 'J', pv: 200,
        },
        {
          name: 'J', pv: 100,
        },
        {
          name: 'A', pv: 400,
        },
        {
          name: 'S', pv: 600,
        },
        {
          name: 'O', pv: 800,
        },
        {
          name: 'N', pv: 100,
        },
      ];

      const listKey = [{
        stroke: '#454545',
        dataKey: 'pv',
      }];

      const equities = [
        {
          id: 1,
          label: 'NW Equity',
          value: 'Rp. 12,375,644,361',
        },
        {
          id: 2,
          label: 'NW Equity II',
          value: 'Rp. 31,035,988,850',
        },
      ];

      const fixedIncome = [
        {
          label: 'NW Fixed Income II',
          value: 'Rp. 23,661,336,935',
        },
      ];

      const moneyMarket = [
        {
          label: 'NW Money Market',
          value: 'Rp. 25,572,791,241',
        },
      ];
      return (
        <LayoutWithNavbar
          header={(
            <Navbar
            //   title={intl.formatMessage({ id: 'goal.defineYourGoal' })}
              title="Goal Details"
              onClickLeft={this.onBackButton}
              leftButton={<FontAwesomeIcon icon="chevron-left" />}
            />
          )}
        >
          <Card
            header={(
              <CardHeader
                icon={<img src={iconEducateOn} alt="icon" />}
                title={intl.formatMessage({ id: 'myGoals.putraEducation' })}
                subTitle="Rp. 520,000,000"
                desc={(
                  <>
                    <b>+ Rp. 678,000</b>
                    <span>(&uarr; 4.06%)</span>
                  </>
                    )}
              />
            )}
          >
            <ProgressBar percent={86} description={intl.formatMessage({ id: 'myGoals.putraEducationProgress' })} />
            <div className="nw-display--flex">
              {dataGoal && dataGoal.map(item => (
                <PercentageBox
                  title={item.title}
                  percent={item.percent}
                  color={item.color}
                  key={item.title}
                />
              ))}
            </div>

            <Button isOutline isFullWidth value={intl.formatMessage({ id: 'portfolio.deviation_analysis' })} onButtonClick={() => this.props.history.push(PATH.DEVIATION_ANALYSIS)} />
          </Card>
          <SubTitle label={intl.formatMessage({ id: 'portfolio.performance' })} />
          <NWLineChartWithFilter data={dataChart} listKey={listKey} activeDot={{ stroke: '#3485ff', fill: '#3485ff' }} />

          <SubTitle label={intl.formatMessage({ id: 'portfolio.portfolio_holdings' })} />
          <ItemList isPortfolio color="#FCD53B" subTitle={intl.formatMessage({ id: 'portfolio.equities' })} value="Rp. 43,411,633,296" listObj={equities} isLink />
          <ItemList isPortfolio color="#F45F32" subTitle={intl.formatMessage({ id: 'portfolio.fixed_income' })} value="Rp. 23,661,336,935" listObj={fixedIncome} />
          <ItemList isPortfolio color="#FCA33B" subTitle={intl.formatMessage({ id: 'portfolio.money_market' })} listObj={moneyMarket} />
        </LayoutWithNavbar>
      );
    }
}

GoalDetail.propTypes = {

};

export default injectIntl(GoalDetail);
