import React, { Fragment } from 'react';
// import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PATH } from 'shared/constant';
import IconClipboard from 'assets/icons/edit@2x.png';
import {
  Card, CardHeader, PercentageBox, NWDonutChart, Button,
} from 'components';
import PropTypes from 'prop-types';
import './MyPortfolio.scss';

const MyPortfolio = ({
  intl, history, isButtonHidden, button, showIcon,
}) => {
  const data = [
    { title: intl.formatMessage({ id: 'portfolio.equities' }), percent: 55, color: '#FCD53B' },
    { title: intl.formatMessage({ id: 'portfolio.fixed_income' }), percent: 22, color: '#F45F32' },
    { title: intl.formatMessage({ id: 'portfolio.money_market' }), percent: 23, color: '#FCA33B' },
  ];

  return (
    <Fragment>
      {/* <Link to={PATH.PORTFOLIO_DETAIL} className="my-portfolio"> */}
      <Card
        header={(
          <CardHeader
            icon={(
              <NWDonutChart
                data={[{ value: 55 }, { value: 22 }, { value: 23 }]}
                colors={['#FCD53B', '#F45F32', '#FCA33B']}
              />
                    )}
            title={intl.formatMessage({ id: 'portfolio.existingPortfolio' })}
            subTitle="Rp. 91,645,761,472"
            desc={(
              <Fragment>
                <b>+ Rp. 678,132</b>
                <span>(&uarr; 4.06%)</span>
              </Fragment>
                    )}
            button={showIcon && <img src={IconClipboard} alt="edit" />}
            onClick={() => history.push(PATH.PORTFOLIO_DETAIL)}
          />
            )}
      >
        <div className="nw-display--flex">
          {data && data.map(item => (
            <PercentageBox
              title={item.title}
              percent={item.percent}
              color={item.color}
              key={item.title}
            />
          ))}
        </div>
        {/* {!isButtonHidden && <Button isOutline isFullWidth value={intl.formatMessage({ id: 'portfolio.portfolioDetails' })} onButtonClick={() => history.push(PATH.PORTFOLIO_DETAIL)} />} */}
        {button}
      </Card>
      {/* </Link> */}
      {!isButtonHidden && <Button value={intl.formatMessage({ id: 'home.assignAGoal' })} onButtonClick={() => history.push(PATH.GOAL)} />}
    </Fragment>
  );
};

MyPortfolio.propTypes = {
  /** bottom button */
  button: PropTypes.node,
  /** Show icon on the right side */
  showIcon: PropTypes.bool,
};

MyPortfolio.defaultProps = {
  button: null,
  showIcon: true,
};

export default injectIntl(MyPortfolio);
