import enMessages from '../../translations/en.json';
import idMessages from '../../translations/id.json';
import { ACTION_TYPE } from '../actions/actionTypes';

const language = (state = [], action) => {
  switch (action.type) {
    case ACTION_TYPE.SWITCH_LANGUAGE:
      return { locale: state.locale === 'en' ? 'id' : 'en', messages: state.locale === 'en' ? idMessages : enMessages };
    default:
      return state;
  }
};

export default language;
