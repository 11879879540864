import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LayoutWithNavbar, Navbar, Card, Button, NWLineChart, TransactionList,
} from 'components';
import { injectIntl } from 'react-intl';
import { PATH } from 'shared/constant';
import './Position.scss';

class Position extends Component {
  state = {
    id: '',
  }

  componentDidMount() {
    const { history: { location } } = this.props;
    const { pathname } = location;
    const id = pathname.split('/position/')[1];
    this.setState({ id });
  }

  onBackButton = () => {
    this.props.history.goBack();
  }

  render() {
    const dataSet = [
      {
        name: 'Jun \'18', Fund: 3400, IDX: 2400,
      },
      {
        name: 'Sep \'18', Fund: 4599, IDX: 5398,
      },
      {
        name: 'Dec \'18', Fund: 9000, IDX: 3800,
      },
      {
        name: 'Mar \'19', Fund: 4780, IDX: 3408,
      },
      {
        name: 'Jun \'19', Fund: 5890, IDX: 4300,
      },
    ];

    const listKeys = [
      {
        dataKey: 'Fund',
        stroke: '#3485ff',
      },
      {
        dataKey: 'IDX',
        stroke: '#34D8FF',
      },
    ];

    const { intl } = this.props;

    return (
      <LayoutWithNavbar
        className="nw-page-position"
        header={(
          <Navbar
            title={intl.formatMessage({ id: 'position.star_capital_protected_fund_v' })}
            onClickLeft={this.onBackButton}
            leftButton={<FontAwesomeIcon icon="chevron-left" />}
          />
        )}
      >
        <div className="investment-actions">
          <Button
            isTertiary
            value={intl.formatMessage({ id: 'investment.sell' })}
            onButtonClick={() => this.props.history.push(PATH.SELL_FUND)}
          />
          <Button
            isSecondary
            value={intl.formatMessage({ id: 'investment.buy' })}
            onButtonClick={() => this.props.history.push(PATH.BUY_FUND)}
          />
        </div>
        <Card header={intl.formatMessage({ id: 'position.yourPositionSince' }, { date: '12/06/2018' })} className="nw-card-position">
          <NWLineChart
            data={dataSet}
            listKey={listKeys}
            customTooltipValue={{
              label: 'NAV: 5,6000',
              value: '(↑ 6.04%)',
            }}
          />
        </Card>
        <Button
          isOutline
          value={intl.formatMessage({ id: 'position.fundDetails' })}
          onButtonClick={() => {
            this.props.history.push(`${PATH.FUND_FACTSHEET}/${this.state.id}`);
          }}
        />
        <TransactionList header={intl.formatMessage({ id: 'transaction.positionDetails' })} />
      </LayoutWithNavbar>
    );
  }
}

export default injectIntl(Position);
