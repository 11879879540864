import React, { useState } from 'react';
import Slider from 'rc-slider';
import { FilterButton } from 'components';

import numeral from 'numeral';

const PastPerformance = () => {
  const [performance, setPerformance] = useState(1020000);
  const handleSlider = (e) => {
    setPerformance(e);
  };
  return (
    <>
      <FilterButton />
      <div className="past-performance__silder">
        <p>
          {`Rp. ${numeral(1000000).format('0,0')}`}
        </p>
        <Slider
          trackStyle={{ backgroundColor: '#000000' }}
          handleStyle={{
            backgroundColor: '#000000',
          }}
          onChange={handleSlider}
          defaultValue={performance}
          max={2000000}
        />
        <p>
          {`Rp. ${numeral(performance).format('0,0')} `}
          <span>↑ 2.00%</span>
        </p>
      </div>
    </>
  );
};

export default PastPerformance;
