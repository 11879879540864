import { ACTION_TYPE } from './actionTypes';

const savePayment = listOfPayment => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.PAYMENT_SUMMARY,
    payload: listOfPayment,
  });
};

const submissionTime = time => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SUBMISSION_TIME,
    payload: time,
  });
};

export default savePayment;

export { submissionTime };
