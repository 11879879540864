import React, { Component } from 'react';
import {
  LayoutWithNavbar, Navbar, Accordion, AccordionGroup, TabsMenu,
} from 'components';
import { injectIntl } from 'react-intl';
import './FAQ.scss';

class FAQ extends Component {
    onBackButton = () => {
      this.props.history.goBack();
    };

    render() {
      const { intl } = this.props;
      const faqs = [
        {
          category: '<Topic Name>',
          title: 'Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus, scele augue?',
          description: 'Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus, scel.',
        },
        {
          category: '<Topic Name>',
          title: 'Vestibulum rutrum quam vitae fringilla tincidunt. Suspendisse nec tortor urna. Ut laoreet?',
          description: 'Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus, scel.',
        },
        {
          category: '<Topic Name>',
          title: 'Nam porttitor blandit accumsan. Ut vel dictum sem, a pretium dui. In malesuada enim in dol?',
          description: 'Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus, scel.',
        },
        {
          category: '<Topic Name>',
          title: 'Mauris non tempor quam, et lacinia sapien. Mauris accumsan eros eget libero posuere vulput?',
          description: 'Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus, scel.',
        },
        {
          category: '<Topic Name>',
          title: 'Fusce vehicula dolor arcu, sit amet blandit dolor mollis nec. Donec viverra eleifend lacus?',
          description: 'Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus, scel.',
        },
        {
          category: '<Topic Name>',
          title: 'Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus, sceleris?',
          description: 'Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus, scel.',
        },
        {
          category: '<Topic Name>',
          title: 'Vestibulum rutrum quam vitae fringilla tincidunt. Suspendisse nec tortor urna. Ut laoreet?',
          description: 'Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus, scel.',
        },
      ];

      return (
        <LayoutWithNavbar
          header={(
            <Navbar
              title={intl.formatMessage({ id: 'faq.faq' })}
              withSideMenu
            />
                )}
        >
          <TabsMenu menu={['All', 'Topic One', 'Topic Two', 'Topic Three']} className="nw-faq-tabs">
            <AccordionGroup>
              { faqs && faqs.map((item, idx) => (
                <Accordion category={item.category} title={item.title} description={item.description} key={`${item.title}${idx}`} />
              ))}
            </AccordionGroup>
          </TabsMenu>
        </LayoutWithNavbar>
      );
    }
}

export default injectIntl(FAQ);
