import { connect } from 'react-redux';
import { addLocaleData, IntlProvider } from 'react-intl';
import englishLocaleData from 'react-intl/locale-data/en';
import indonesianLocaleData from 'react-intl/locale-data/id';

addLocaleData(englishLocaleData);
addLocaleData(indonesianLocaleData);

const mapStateToProps = (state) => {
  const { locale, messages } = state.language;
  return { key: locale, locale, messages };
};

export default connect(mapStateToProps)(IntlProvider);
