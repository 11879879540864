import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FundSummary, Navbar, LayoutWithNavbar } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class FundSummaryPage extends Component {
  render() {
    const { data } = this.props;
    return (
      <LayoutWithNavbar
        header={(
          <Navbar
            title="NW Mutual Funds"
            leftButton={<FontAwesomeIcon icon="chevron-left" />}
            onClickLeft={this.onBackButton}
            rightButton="Filter"
          />
          )}
      >
        <div className="fund-summary-page">
          {data.map((value, key) => (
            <FundSummary
              key={key}
              isClosed={value.closed}
              image={value.image}
              category={value.category}
              fundName={value.fundName}
              description={value.description}
              fundValue={value.fundValue}
            />
          ))}
        </div>
      </LayoutWithNavbar>
    );
  }
}

FundSummaryPage.propTypes = {
  /** */
  data: PropTypes.arrayOf(PropTypes.object),
};

FundSummaryPage.defaultProps = {
  data: [{
    closed: true,
    image: '',
    category: 'Fixed Income',
    fundName: 'NW Balanced',
    description: <p>Double benefits of lower risk, big returns. Double benefits of lower risk, big returns.</p>,
    fundValue: [[
      {
        label: '3y performance',
        value: '+11.68%',
      }, {
        label: 'Min investment',
        value: 'Rp. 500 k',
      },
    ], [
      {
        label: 'Investment horizon',
        value: 'Over 5 y',
      }, {
        label: 'Risk category',
        value: 'Medium',
      },
    ]],
  }, {
    closed: true,
    image: '',
    category: 'Fixed Income',
    fundName: 'NW Balanced',
    description: <p>Double benefits of lower risk, big returns. Double benefits of lower risk, big returns.</p>,
    fundValue: [[
      {
        label: '3y performance',
        value: '+11.68%',
      }, {
        label: 'Min investment',
        value: 'Rp. 500 k',
      },
    ], [
      {
        label: 'Investment horizon',
        value: 'Over 5 y',
      }, {
        label: 'Risk category',
        value: 'Medium',
      },
    ]],
  }, {
    closed: true,
    image: '',
    category: 'Fixed Income',
    fundName: 'NW Balanced',
    description: <p>Double benefits of lower risk, big returns. Double benefits of lower risk, big returns.</p>,
    fundValue: [[
      {
        label: '3y performance',
        value: '+11.68%',
      }, {
        label: 'Min investment',
        value: 'Rp. 500 k',
      },
    ], [
      {
        label: 'Investment horizon',
        value: 'Over 5 y',
      }, {
        label: 'Risk category',
        value: 'Medium',
      },
    ]],
  }, {
    closed: false,
    image: '',
    category: 'Fixed Income',
    fundName: 'NW Balanced',
    description: <p>Double benefits of lower risk, big returns. Double benefits of lower risk, big returns.</p>,
    fundValue: [[
      {
        label: '3y performance',
        value: '+11.68%',
      }, {
        label: 'Min investment',
        value: 'Rp. 500 k',
      },
    ], [
      {
        label: 'Investment horizon',
        value: 'Over 5 y',
      }, {
        label: 'Risk category',
        value: 'Medium',
      },
    ]],
  }],
};

export default FundSummaryPage;
