import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconPDF from 'assets/icons/iconPdf.png';
// import baseUrl from 'shared/utils/baseUrl';

const DownloadItem = (props) => {
  const { name, url, icon } = props;
  return (
    <Link to={url} className="nw-downloads__item" target="_blank">
      <img src={icon || IconPDF} alt={name} />
      <span>{name}</span>
    </Link>
  );
};

DownloadItem.propTypes = {
  /** Icon source */
  icon: PropTypes.string,
  /** Icon Name */
  name: PropTypes.string,
  /** Icon Url */
  url: PropTypes.string,
};

DownloadItem.defaultProps = {
  icon: '',
  name: '',
  url: '/',
};

export default DownloadItem;
