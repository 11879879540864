import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
import enMessages from '../translations/en.json';
// import idMessages from '../translations/id.json';

import {
  ENGLISH,
//   BAHASA,
} from '../constant/index';

export const initialState = {
  toggleMenu: { openMenu: false },
  homeTabs: { currentNav: 0 },
  language: { locale: ENGLISH, messages: enMessages },
  // myInvestment: { currentNav: null },
  catalogue: [{
    id: '1',
    status: 'High Risk',
    banner: 'banner-equity.png',
    isInvest: true,
    category: {
      label: 'Equity',
      href: '#',
    },
    title: 'NW SICAV EM Frontier Equity Fund',
    description: 'Benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 171 k',
      descr: 'Minimum',
    }, {
      value: '5,6000',
      descr: 'NAV',
    }],
  }, {
    id: '2',
    status: 'Medium Risk',
    banner: 'banner-equity2.png',
    isInvest: true,
    category: {
      label: 'Equity',
      href: '#',
    },
    title: 'NW SICAV EM Active Equity Fund',
    description: 'Benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 133 k',
      descr: 'Minimum',
    }, {
      value: '5,6000',
      descr: 'NAV',
    }],
  }, {
    id: '3',
    status: 'High Risk',
    banner: 'graph@2x.png',
    isInvest: true,
    category: {
      label: 'Fixed Income',
      href: '#',
    },
    title: 'NW Fixed Income Dynamic',
    description: 'Benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 500 k',
      descr: 'Minimum',
    }, {
      value: '5,6000',
      descr: 'NAV',
    }],
  }, {
    id: '4',
    status: 'Low Risk',
    banner: 'eggs@2x.png',
    isInvest: true,
    category: {
      label: 'Fixed Income',
      href: '#',
    },
    title: 'NW Fixed Income III',
    description: 'Double benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 500 k',
      descr: 'Minimum',
    }, {
      value: '18,6001',
      descr: 'NAV',
    }],
  }, {
    id: '5',
    status: 'Low Risk',
    banner: 'banner-balanced.png',
    isInvest: true,
    category: {
      label: 'Balanced',
      href: '#',
    },
    title: 'NW Balanced Syariah Fund',
    description: 'Double benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 500 k',
      descr: 'Minimum',
    }, {
      value: '18,6001',
      descr: 'NAV',
    }],
  }, {
    id: '6',
    status: 'Medium Risk',
    banner: 'money@2x.png',
    isInvest: true,
    category: {
      label: 'Money Market',
      href: '#',
    },
    title: 'Capital Protected Fund V',
    description: 'Double benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 500 k',
      descr: 'Minimum',
    }, {
      value: '18,6001',
      descr: 'NAV',
    }],
  }, {
    id: '7',
    status: 'Low Risk',
    banner: 'hands@2x.png',
    isInvest: true,
    category: {
      label: 'Fixed Income',
      href: '#',
    },
    title: 'Fixed Income II',
    description: 'Benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 500 k',
      descr: 'Minimum',
    }, {
      value: '18,6119',
      descr: 'NAV',
    }],
  }, {
    id: '8',
    status: 'Low Risk',
    banner: 'banner-money-market.png',
    isInvest: false,
    category: {
      label: 'Equity',
      href: '#',
    },
    title: 'NW SICAV EM Indonesian Equity Fund',
    description: 'Double benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 500 k',
      descr: 'Minimum',
    }, {
      value: '18,6001',
      descr: 'NAV',
    }],
  }, {
    id: '9',
    status: 'Medium Risk',
    banner: 'banner-fixed-income.png',
    isInvest: false,
    category: {
      label: 'Fixed Income',
      href: '#',
    },
    title: 'NW Fixed Income IV',
    description: 'Double benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 500 k',
      descr: 'Minimum',
    }, {
      value: '18,6001',
      descr: 'NAV',
    }],
  },
  ],
  riskProfile: {
    getRiskQuestionPending: false,
    getRiskQuestionSuccess: false,
    getRiskQuestionFailure: false,
    getRiskQuestionError: '',
    questions: null,
    submitAnswerPending: false,
    submitAnswerSuccess: false,
    submitAnswerFailure: false,
    submitAnswerError: '',
    score: null,
    profile: null,
    profileDesc: null,
  },
  payment: {
    listOfPayment: [],
    submissionTime: '',
  },
  transaction: {
    filterList: [
      { value: 'Subscription', title: 'Type' },
      { value: 'Redemption', title: 'Type' },
      { value: 'Pending', title: 'Status' },
      { value: 'In Process', title: 'Status' },
      { value: 'Completed', title: 'Status' },
      { value: 'Balanced II', title: 'Funds' },
      { value: 'CPF', title: 'Funds' },
      { value: 'CPF II', title: 'Funds' },
      { value: 'Fixed Income Dollar', title: 'Funds' },
      { value: 'Equity', title: 'Funds' },
      { value: 'Equity II', title: 'Funds' },
      { value: '6M', title: 'Date range' },
    ],
    filters: [
      {
        title: 'Type',
        options: [
          'Subscription',
          'Redemption',
        ],
        default: [
          'Subscription',
          'Redemption',
        ],
      },
      {
        title: 'Status',
        options: [
          'Pending',
          'In Process',
          'Completed',
        ],
        default: [
          'Pending',
          'In Process',
          'Completed',
        ],
      },
      {
        title: 'Funds',
        options: [
          'Balanced II',
          'CPF',
          'CPF II',
          'Fixed Income Dollar',
          'Equity',
          'Equity II',
        ],
        default: [
          'Balanced II',
          'CPF',
          'CPF II',
          'Fixed Income Dollar',
          'Equity',
          'Equity II',
        ],
      },
      {
        title: 'Date range',
        options: [
          '1M',
          '3M',
          '6M',
          '1Y',
          '3Y',
        ],
        default: '6M',
        type: 'horizontal',
      },
    ],
  },
};

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

const store = createStore(rootReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
    ),
  ));

export default store;
