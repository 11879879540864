import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import { TabsMenu } from 'components';
import History from './menus/History';
import PastPerformance from './menus/PastPerformance';
import FundMultiplier from './menus/FundMultiplier';


class Performance extends Component {
  render() {
    return (
      <TabsMenu
        className="fund-factsheet__menu"
        menu={['Performance', 'Multiplier', 'NAV History']}
      >
        <PastPerformance />
        <FundMultiplier />
        <History />
      </TabsMenu>
    );
  }
}

Performance.propTypes = {

};

export default Performance;
