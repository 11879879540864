import React, { Component } from 'react';
import {
  LayoutWithNavbar, Navbar, Card, Item, InputBox,
  Button, Agreement, SelectBox,
} from 'components';
import { injectIntl, FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PATH } from 'shared/constant';
import numeral from 'numeral';
import './SellFund.scss';

class SellConfirmation extends Component {
  constructor(props) {
    super(props);
    const isSelectAll = props.location.state && props.location.state.amount === 194569260.64;
    this.state = {
      bank: 'Bank Mandiri',
      ac: 7775778992,
      acceptCON: false,
      summary: [
        {
          label: props.intl.formatMessage({ id: 'sellFund.selling' }),
          value: 'Capital Protected Fund V',
        },
        {
          label: props.intl.formatMessage({ id: isSelectAll ? 'sellFund.noOfUnits' : 'sellFund.estimatedOfUnits' }),
          value: props.location.state.totalUnits,
        },
      ],
    };
  }

  componentDidMount() {
    const { intl } = this.props;
    const isSelectAll = this.props.location.state && this.props.location.state.amount === 194569260.64;

    // eslint-disable-next-line react/no-access-state-in-setstate
    const newSummary = [...this.state.summary];

    if (!isSelectAll) {
      newSummary.splice(1, 0, {
        label: intl.formatMessage({ id: 'sellFund.estimatedAmount' }),
        value: `Rp. ${this.props.location.state ? numeral(this.props.location.state.amount).format('0,0.00') : '100,000,000'}`,
      });
    } else {
      newSummary.push({
        label: intl.formatMessage({ id: 'sellFund.estimatedValue' }),
        value: 'Rp. 194,569,260.64',
      });
    }

    this.setState({
      summary: newSummary,
    });
  }

  onBackButton = () => {
    this.props.history.goBack();
  };

  render() {
    const { intl } = this.props;

    const banks = [
      {
        name: 'Bank Rakyat Indonesia',
      },
      {
        name: 'Bank Mandiri',
      },
      {
        name: 'Bank Central Asia (BCA)',
      },
      {
        name: 'Bank Negara Indonesia (BNI)',
      },
      {
        name: 'Bank Tabungan Negara (BTN)',
      },
      {
        name: 'Bank BPD Bali',
      },
      {
        name: 'Bank BPD DIY',
      },
      {
        name: 'Bank Banten',
      },
      {
        name: 'Bank Bengkulu',
      },
      {
        name: 'Bank BJB',
      },
      {
        name: 'Bank DKI',
      },
      {
        name: 'Bank Jambi',
      },
      {
        name: 'Bank Papua',
      },
      {
        name: 'Bank Sultra',
      },
      {
        name: 'Bank Sumut',
      },
    ];

    const { summary, acceptCON } = this.state;

    return (
      <LayoutWithNavbar
        className="nw-page-sellFund"
        header={(
          <Navbar
            title={intl.formatMessage({ id: 'sellFund.confirmation' })}
            onClickLeft={this.onBackButton}
            leftButton={<FontAwesomeIcon icon="chevron-left" />}
          />
                )}
      >
        <Card>
          {summary && summary.map(item => (
            <Item label={item.label} value={item.value} key={item.label} />
          ))}
        </Card>
        <Card header={intl.formatMessage({ id: 'sellFund.bankAccountDetails' })}>
          <SelectBox
            title={intl.formatMessage({ id: 'sellFund.bankName' })}
            dataList={banks.map(item => item.name)}
            defaultValue={this.state.bank}
            onSelect={e => this.setState({
              bank: e,
              ac: '',
            })}
          />
          <div className="nw-redemption-account">
            <InputBox
              title={intl.formatMessage({ id: 'sellFund.accountName' })}
              value="Ivan"
              isDisabled
            />
            <InputBox
              title={intl.formatMessage({ id: 'sellFund.acNumber' })}
              value={this.state.ac}
              onInputChange={e => this.setState({ ac: e.target.value })}
            />
          </div>
        </Card>
        <Agreement
          agreement={<FormattedMessage id="sellFund.cutOffFull" values={{ cutOff: <b>{intl.formatMessage({ id: 'sellFund.cutOff' })}</b> }} />}
          onCheckAgree={() => {
            this.setState({ acceptCON: !acceptCON });
          }}
        />
        <Button
          isDisabled={!acceptCON}
          value={intl.formatMessage({ id: 'sellFund.continue' })}
          onButtonClick={() => this.props.history.push(PATH.OTP)}
          isTertiary
        />
      </LayoutWithNavbar>
    );
  }
}

export default injectIntl(SellConfirmation);
