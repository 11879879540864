import React, { useEffect, useRef } from 'react';
import { FilterBox, Button } from 'components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './TransactionFilter.scss';

const TransactionFilter = ({
 visible, onOk, onHandleFilterList, filters 
}) => {
  // const filters = [
  //   {
  //     title: 'Portfolio',
  //     options: [
  //       'Existing Portfolio',
  //       'Portfolio 2',
  //     ],
  //     default: ['Existing Portfolio'],
  //   },
  //   {
  //     title: 'Mutual Funds',
  //     options: [
  //       'Balanced II',
  //       'CPF',
  //       'CPF II',
  //       'Fixed Income Dollar',
  //       'Equity',
  //       'Equity II',
  //     ],
  //     default: [
  //       'Balanced II',
  //       'CPF',
  //       'CPF II',
  //       'Fixed Income Dollar',
  //       'Equity',
  //       'Equity II',
  //     ],
  //   },
  //   {
  //     title: 'Date range',
  //     options: [
  //       '1M',
  //       '3M',
  //       '6M',
  //       '1Y',
  //       '3Y',
  //     ],
  //     default: '6M',
  //     type: 'horizontal',
  //   },
  // ];

  const filterClass = classNames({
    'transaction-filter--open': visible,
  });

  const filter = useRef(null);

  const hideOnClickOutside = (e) => {
    if (!filter.current.contains(e.target) && visible) {
      onOk();
    }
  };

  useEffect(() => {
    const App = document.querySelector('.App');
    App.addEventListener('click', hideOnClickOutside);
    return () => {
      App.removeEventListener('click', hideOnClickOutside);
    };
  });

  return (
    <div className={`transaction-filter ${filterClass}`}>
      <div className="transaction-filter__container" ref={filter}>
        {filters && filters.map(item => (
          <FilterBox
            key={item.title}
            title={item.title}
            options={item.options}
            type={item.type}
            name={_.camelCase(item.title)}
            defaultValue={item.default}
            onSelect={e => onHandleFilterList(e, item.title)}
          />
        ))}

        <Button value="Apply Filters" onButtonClick={onOk} />
      </div>
    </div>
  );
};

TransactionFilter.propTypes = {
  /** filter visible */
  visible: PropTypes.bool,
  /** filter onOK */
  onOk: PropTypes.func,
  // update filter list status
  onHandleFilterList: PropTypes.func,
};

TransactionFilter.defaultProps = {
  visible: false,
  onOk: () => {},
  onHandleFilterList: () => {},
};

export default TransactionFilter;
