import React from 'react';
import { NWLineChartWithFilter } from 'components';

const History = () => {
  const data = [
    {
      name: 'J', pv: 400,
    },
    {
      name: 'F', pv: 300,
    },
    {
      name: 'M', pv: 500,
    },
    {
      name: 'A', pv: 100,
    },
    {
      name: 'M', pv: 600,
    },
    {
      name: 'J', pv: 200,
    },
    {
      name: 'J', pv: 100,
    },
    {
      name: 'A', pv: 400,
    },
    {
      name: 'S', pv: 600,
    },
    {
      name: 'O', pv: 800,
    },
    {
      name: 'N', pv: 100,
    },
  ];

  const listKey = [{
    stroke: '#454545',
    dataKey: 'pv',
  }];
  return (
    <>
      <NWLineChartWithFilter data={data} listKey={listKey} activeDot={{ stroke: '#3485ff', fill: '#3485ff' }} />
    </>
  );
};


export default History;
