export const ENGLISH = 'en';
export const BAHASA = 'id';

// Default chart colors in order
export const COLORS = ['#fcd53b', '#3485ff', '#f45f32', '#fca33b', '#89c5cc', '#5ac8fa', '#5856d6', '#007aff', '#00c693'];
export const PATH = {
  HOME: '/my-investment',
  TRANSACTION_HISTORY: '/transaction-history',
  CATALOGUE: '/catalogue',
  PORTFOLIO_DETAIL: '/portfolio-details',
  DEVIATION_ANALYSIS: '/deviation-analysis',
  POSITION: '/position',
  RISK_PROFILING: '/risk-profiling',
  RISK_QUESTIONS: '/risk-profiling/questions',
  FAQ: '/faq',
  CATALOGUE_INTERNAL: '/catalogue-internal',
  LOGIN: '/',
  GOAL: '/goal',
  GOAL_DETAIL: '/goal-detail',
  FUND_FACTSHEET: '/fundfactsheet',
  OTP: '/otp',
  BUY_FUND: '/buy-fund',
  PAYMENT: '/payment',
  PAYMENT_CARD: '/payment-via-debit-card',
  PAYMENT_CONFIRM: '/payment-confirmation',
  PAYMENT_COMPLETE: '/payment-complete',
  SELL_FUND: '/sell-fund',
  SELL_CONFIRMATION: '/sell-fund/confirmation',
  ORDER_RECEIVED: '/order-received',
};

export const STORAGE = {
  RISK_EXPIRE: 'RISK_EXPIRE',
};
