import React from 'react';
import PropTypes from 'prop-types';
import './NWTooltip.scss';

const NWTooltip = ({
  active,
  // label,
  // payload,
  customLabel,
  customValue,
}) => {
  if (active) {
    return (
      <div className="nw-tooltip">
        <header>
          <p className="label">{customLabel}</p>
        </header>
        <section>
          <b>{customValue.label}</b>
          &nbsp;
          <p>
            {customValue.value}
          </p>
        </section>
      </div>
    );
  }

  return null;
};

NWTooltip.propTypes = {
  /** Tooltip day */
  // payload: PropTypes.arrayOf(PropTypes.object),
  /** Custom label for tooltip */
  customLabel: PropTypes.string,
  /** Custom value for tooltip */
  customValue: PropTypes.objectOf(PropTypes.string),
};

NWTooltip.defautProps = {
  customLabel: '25/07/2019',
  customValue: {
    label: 'Value: 18,6119',
    value: '(↑ 6.04%)',
  },
};

export default NWTooltip;
