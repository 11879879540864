import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LayoutWithNavbar, Navbar, Card, Input, NWSlider, Button, IconButton,
} from 'components';
import { injectIntl, FormattedMessage } from 'react-intl';
import numeral from 'numeral';
import { setTab } from 'shared/redux/actions';
import iconRetire from 'assets/icons/iconRetire.png';
import iconRetireOn from 'assets/icons/iconRetireOn.png';
import iconEducate from 'assets/icons/iconEducate.png';
import iconEducateOn from 'assets/icons/iconEducateOn.png';
import iconGrowth from 'assets/icons/iconGrowth.png';
import iconGrowthOn from 'assets/icons/iconGrowthOn.png';
import iconHouse from 'assets/icons/iconHouse.png';
import iconHouseOn from 'assets/icons/iconHouseOn.png';
import './Goal.scss';

class Goal extends Component {
  constructor() {
    super();
    this.state = {
      currentGoal: 0,
      targetCompletion: 6,
      targetAmount: 0,
    };
    this.handleSlider = this.handleSlider.bind(this);
  }

    onBackButton = () => {
      this.props.history.goBack();
    };

    handleSlider = (e) => {
      this.setState({ targetCompletion: e });
    };

    handleInputChange = (e) => {
      const targetAmount = numeral(e.target.value).format('0');
      this.setState({ targetAmount });
    }

    render() {
      const { intl } = this.props;
      const goals = [
        {
          icon: <img src={iconRetire} alt="icon" />,
          activeIcon: <img src={iconRetireOn} alt="icon" />,
          value: intl.formatMessage({ id: 'goal.retirement' }),
        },
        {
          icon: <img src={iconEducate} alt="icon" />,
          activeIcon: <img src={iconEducateOn} alt="icon" />,
          value: intl.formatMessage({ id: 'goal.education' }),
        },
        {
          icon: <img src={iconHouse} alt="icon" />,
          activeIcon: <img src={iconHouseOn} alt="icon" />,
          value: intl.formatMessage({ id: 'goal.house' }),
        },
        {
          icon: <img src={iconGrowth} alt="icon" />,
          activeIcon: <img src={iconGrowthOn} alt="icon" />,
          value: intl.formatMessage({ id: 'goal.assetGrowth' }),
        },
      ];

      return (
        <LayoutWithNavbar
          header={(
            <Navbar
              title={intl.formatMessage({ id: 'goal.defineYourGoal' })}
              onClickLeft={this.onBackButton}
              leftButton={<FontAwesomeIcon icon="chevron-left" />}
            />
                    )}
        >
          <Card header={intl.formatMessage({ id: 'goal.selectYourGoal' })} className="nw-card-goals">
            <div className="nw-display--flex">
              {
                    goals && goals.map((item, idx) => (
                      <IconButton
                        key={item.value}
                        value={item.value}
                        icon={item.icon}
                        activeIcon={item.activeIcon}
                        isActive={this.state.currentGoal === idx}
                        onButtonClick={() => this.setState({ currentGoal: idx })}
                      />
                    ))
                }
            </div>
          </Card>

          <Card header={intl.formatMessage({ id: 'goal.setTargetAmount' })}>
            <Input name="target" type="tel" placeholder="Rp. 500,000,000" onChange={this.handleInputChange} value={numeral(this.state.targetAmount).format('0,0')} />
          </Card>

          <Card header={(
            <div className="nw-card__header--flex">
              <FormattedMessage id="goal.setTargetCompletion" />
              <span style={{ fontWeight: 'bold' }}>
                {intl.formatMessage({ id: 'goal.year' }, { value: this.state.targetCompletion })}
              </span>
            </div>
)}
          >

            <NWSlider min={1} max={12} defaultValue={6} onChange={this.handleSlider} />
          </Card>
          <Button
            value={intl.formatMessage({ id: 'risk.continue' })}
            onButtonClick={() => {
              this.props.makeMyGoalsTabActive();
              this.props.history.goBack();
            }}
          />
        </LayoutWithNavbar>
      );
    }
}

const mapDispatchToProps = dispatch => ({
  makeMyGoalsTabActive: () => dispatch(setTab(1)),
});

export default injectIntl(connect(null, mapDispatchToProps)(Goal));
