import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './Item.scss';

/**
 * Item
 * @param {label, value, isLink} props
 * item with label on the left and value on the right
 */
const Item = (props) => {
  const {
    id, label, value, isLink, withNotes, className,
    isPortfolio, color,
  } = props;
  const classes = classNames({
    'nw-item': true,
    'nw-itemList__item': true,
    'nw-item--withNotes': withNotes,
    'nw-item--colored': isPortfolio,
  });
  if (isLink) {
    return (
      <Link to={`/position/${id}`} className={`${className} ${classes}`}>
        <div>
          <span>{label}</span>
          <span className="nw-item__value">{value}</span>
        </div>
      </Link>
    );
  }
  return (
    <section className={`${className} ${classes}`}>
      <div>
        <div>{label}</div>
        <span className="nw-item__value">{value}</span>
        {isPortfolio && (
          <i style={{ background: color }} />
        )}
      </div>
      {withNotes && (
        <small>
          <strong>NAV: 18.6001</strong>
          <span>as of</span>
          <span>14/07/2019</span>
        </small>
      )}
    </section>
  );
};

Item.propTypes = {
  /** item ID */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** item label */
  label: PropTypes.node,
  /** item value */
  value: PropTypes.string,
  /** is item a link */
  isLink: PropTypes.bool,
  /** with notes below? */
  withNotes: PropTypes.bool,
  /** class name */
  className: PropTypes.string,
  /** colors */
  color: PropTypes.string,
  /** isPortfolio */
  isPortfolio: PropTypes.bool,
};

Item.defaultProps = {
  id: null,
  label: null,
  value: null,
  isLink: false,
  withNotes: false,
  className: '',
  color: null,
  isPortfolio: false,
};

export default Item;
