import React from 'react';
import { TabsMenu } from 'components';
import { injectIntl } from 'react-intl';
import Login from './Login';
import './LoginPage.scss';

const LoginPage = ({ intl, history }) => (
  <div className="login-page">
    <TabsMenu menu={[intl.formatMessage({ id: 'login.login' })]}>
      <Login history={history} />
    </TabsMenu>
  </div>
);

export default injectIntl(LoginPage);
