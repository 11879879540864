import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { COLORS } from 'shared/constant/index';
import Card from '../Card/Card';
import NWDonutChart from '../Chart/NWDonutChart/NWDonutChart';
import './DonutChartList.scss';

/**
 * DonutChartList
 * @param { subTitle, isListBelow, data } props
 * Common donut chart with list pertaining to the chart
 */

const DonutChartList = (props) => {
  const {
    subTitle, isListBelow, data, colors,
  } = props;
  const classes = classNames({
    'donut-chart-list': true,
    'donut-chart-list--list-below': isListBelow,
  });
  return (
    <Card header={subTitle}>
      <section className={classes}>
        <div className="donut-chart-list__chart">
          <NWDonutChart data={data} colors={colors} innerRadius="64%" />
        </div>
        <ul className="donut-chart-list__items">
          { data && data.map((item, i) => (
            <li key={`al-${i}`}>
              <div>
                <span>{item.name}</span>
                <strong>{`${item.value}%`}</strong>
              </div>
              <span className="donut-chart-list__items__identifier" style={{ backgroundColor: colors[i] }} />
            </li>
          )) }
        </ul>
      </section>
    </Card>
  );
};

DonutChartList.propTypes = {
  /** Card Subtitle */
  subTitle: PropTypes.string,
  /** Do you want to move list below */
  isListBelow: PropTypes.bool,
  /** Allocation Data */
  data: PropTypes.arrayOf(PropTypes.object),
  /** Data colors */
  colors: PropTypes.arrayOf(PropTypes.string),
};

DonutChartList.defaultProps = {
  subTitle: 'Asset Allocation',
  isListBelow: false,
  colors: COLORS,
  data: [
    { name: 'Corp Bonds', value: 86.06 },
    { name: 'Time Deposit', value: 13.94 },
  ],
};

export default DonutChartList;
