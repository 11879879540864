/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BlueLink } from 'components';
import './InputBox.scss';

const InputBox = ({
  title, value, isDisabled, currency, rightButtonText, onButtonClick, type, onInputChange, customClass, autoFocus, placeholder,
}) => {
  const [isFocus, setFocus] = useState(autoFocus);
  const [inputValue, setInputValue] = useState(value);

  const classes = classNames({
    'nw-input-box--currency': currency,
    'nw-input-box--disabled': isDisabled,
    'nw-input-box--focused': isFocus,
  });

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className={`nw-input-box ${customClass} ${classes}`}>
      <h3>{title}</h3>
      <div>
        <span>{currency}</span>
        <div className="nw-input-box__input">
          <input
            autoFocus={autoFocus}
            type={type}
            value={inputValue}
            disabled={isDisabled}
            spellCheck={false}
            onChange={onInputChange}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            placeholder={placeholder}
            pattern={type === 'tel' ? '[0-9]*' : null}
          />
          {rightButtonText && <BlueLink onClick={onButtonClick} value={rightButtonText} />}
        </div>
      </div>
    </div>
  );
};

InputBox.propTypes = {
  // box title
  title: PropTypes.string,
  // input default value
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // input disable
  isDisabled: PropTypes.bool,
  // input currency (optional)
  currency: PropTypes.string,
  // input change
  onInputChange: PropTypes.func,
  // input type
  type: PropTypes.string,
  // Addition input class
  customClass: PropTypes.string,
  /** auto focus? */
  autoFocus: PropTypes.bool,
};

InputBox.defaultProps = {
  title: '',
  value: '',
  isDisabled: false,
  currency: '',
  onInputChange: () => {},
  type: 'text',
  customClass: '',
  autoFocus: false,
};

export default InputBox;
