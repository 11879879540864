import { ACTION_TYPE } from '../actions/actionTypes';

const toggleMenu = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.TOGGLE_MENU:
      return { ...state, openMenu: action.visible };
    default:
      return state;
  }
};

export default toggleMenu;
