import React from 'react';
import PropTypes from 'prop-types';
import DownloadItem from './DownloadItem';
import Card from '../Card/Card';
import SubTitle from '../SubTitle/SubTitle';
import './Downloads.scss';

const Downloads = props => (
  <Card
    header={(
      <SubTitle label={props.title} />
    )}
  >
    <div className="nw-downloads">
      { props.downloadList && props.downloadList.map((item, i) => (
        <DownloadItem
          key={`dl-${i}`}
          {...item}
        />
      )) }
    </div>
  </Card>
);

Downloads.propTypes = {
  /** Widget Title */
  title: PropTypes.string,
  /** Download list (arr of object) */
  downloadList: PropTypes.arrayOf(PropTypes.object),
};

Downloads.defaultProps = {
  title: 'Downloads',
  downloadList: [
    {
      icon: null,
      name: 'Factsheet',
      url: '/',
    },
    {
      icon: null,
      name: 'Prospectus',
      url: '/',
    },
    {
      icon: null,
      name: 'Annual report',
      url: '/',
    },
  ],
};

export default Downloads;
