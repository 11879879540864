import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SubTitle.scss';

/**
 * SubTitle
 * @param {label, linkLabel, linkUrl} props
 * subtitle used before Card container
 */
const SubTitle = (props) => {
  const {
    label, linkLabel, linkUrl, noTopSpace,
  } = props;
  const classes = classNames({
    'nw-subTitle': true,
    'nw-subTitle--noTopSpace': noTopSpace,
  });
  return (
    <div className={classes}>
      <strong>{label}</strong>
      {/* Add arrow icon on the Link through CSS when icon is ready */}
      { linkUrl && (
      <Link to={linkUrl}>
        {linkLabel}
        {' '}
        <FontAwesomeIcon icon="chevron-right" />
      </Link>
      ) }
    </div>
  );
};

SubTitle.propTypes = {
  /** Sub title label */
  label: PropTypes.string,
  /** Link label */
  linkLabel: PropTypes.string,
  /** Link url */
  linkUrl: PropTypes.string,
  /** Remove space above? */
  noTopSpace: PropTypes.bool,
};

SubTitle.defaultProps = {
  label: 'Subtitle here',
  linkLabel: '',
  linkUrl: null,
  noTopSpace: false,
};

export default SubTitle;
