import { combineReducers } from 'redux';
import language from './language';
import toggleMenu from './toggleMenu';
import catalogue from './catalogue';
import homeTabs from './homeTabs';
import payment from './payment';
import transaction from './transaction';
import riskProfile from './riskProfile';

const rootReducer = combineReducers({
  language,
  toggleMenu,
  catalogue,
  homeTabs,
  payment,
  transaction,
  riskProfile,
});

// const rootReducer = (state, action) => {
//   const modifiedState = state;

//   return appReducer(modifiedState, action);
// };

export default rootReducer;
