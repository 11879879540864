import { ACTION_TYPE } from '../actions/actionTypes';

const transaction = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.TRANSACTION_FILTER:
      return { ...state, filterList: action.payload };
    default:
      return state;
  }
};

export default transaction;
