/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Component } from 'react';
import {
  LayoutWithNavbar, Navbar, Card, Item, InputBox, Agreement,
  Button,
} from 'components';
import { injectIntl, FormattedMessage } from 'react-intl';
import numeral from 'numeral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PATH } from 'shared/constant';
import './SellFund.scss';

class SellFund extends Component {
  constructor() {
    super();
    this.state = {
      sellAll: false,
      lastAmount: 0,
      amount: 0,
      totalUnits: 1800.6219,
      acceptTOS: false,
    };
  }

    onBackButton = () => {
      this.props.history.goBack();
    };

    handleSelect = (e) => {
      this.setState({
        acceptTOS: e.target.checked,
      });
    };

    handleInputChange = (e) => {
      this.setState({
        amount: numeral(e.target.value).format('0'),
        lastAmount: numeral(e.target.value).format('0'),
        sellAll: e.target.value === 194569260.64,
      });
    };

    render() {
      const { intl } = this.props;

      const summary = [
        {
          label: intl.formatMessage({ id: 'sellFund.selling' }),
          value: 'Capital Protected Fund V',
          notes: false,
        },
        {
          label: intl.formatMessage({ id: 'sellFund.totalUnitsAvailable' }),
          value: '108,056.7001',
          notes: false,
        },
        {
          label: <FormattedMessage id="sellFund.navPerUnit" values={{ date: <span className="date">(as of 20/08/2019)</span> }} />,
          value: '1,800.6219',
          notes: false,
          className: 'nw-item--nav',
        },
        {
          label: intl.formatMessage({ id: 'sellFund.currentValue' }),
          value: 'Rp. 194,569,260.64',
          notes: false,
          className: 'nw-item--currentValue',
        },
      ];

      const { amount, acceptTOS } = this.state;

      const isAllowed = () => {
        if (amount >= 100000 && acceptTOS) return false;
        return true;
      };
      return (
        <LayoutWithNavbar
          className="nw-page-sellFund"
          header={(
            <Navbar
              title={intl.formatMessage({ id: 'sellFund.sellFund' })}
              onClickLeft={this.onBackButton}
              leftButton={<FontAwesomeIcon icon="chevron-left" />}
            />
            )}
        >
          <Card>
            {summary && summary.map(item => (
              <Item label={item.label} value={item.value} key={item.label} withNotes={item.notes} className={item.className} />
            ))}
          </Card>
          <Card header={intl.formatMessage({ id: 'sellFund.inputSellingDetails' })}>
            <InputBox
              type="tel"
              title={
                this.state.sellAll
                  ? intl.formatMessage({ id: 'sellFund.estimatedAmountRedeem' })
                  : intl.formatMessage({ id: 'sellFund.amountRedeem' })
              }
              value={numeral(amount).format(0, 0)}
              currency="Rp."
              onInputChange={this.handleInputChange}
              rightButtonText={
                this.state.sellAll
                  ? intl.formatMessage({ id: 'sellFund.undo' })
                  : intl.formatMessage({ id: 'sellFund.sellAll' })
              }
              onButtonClick={
                () => (this.state.sellAll
                  ? this.setState((state, prevState) => ({ amount: state.lastAmount + prevState.amount, sellAll: false }))
                  : this.setState({ amount: 194569260.64, sellAll: true }))
              }
            />
            <InputBox
              title={intl.formatMessage({ id: 'sellFund.noOfUnitsYouSelling' })}
              value={numeral(amount / 1800.6219).format('0,0[.]0000')}
              isDisabled
            />
          </Card>
          <Agreement
            agreement={intl.formatMessage({ id: 'sellFund.iHaveRead' })}
            prospectus={intl.formatMessage({ id: 'sellFund.viewPros' })}
            onCheckAgree={() => {
              this.setState({ acceptTOS: !acceptTOS });
            }}
          />
          <Button
            value={intl.formatMessage({ id: 'sellFund.continue' })}
            isDisabled={isAllowed()}
            onButtonClick={() => this.props.history.push({
              pathname: PATH.SELL_CONFIRMATION,
              state: {
                totalUnits: numeral(this.state.amount / this.state.totalUnits).format('0,0.0000'),
                amount: this.state.amount,
              },
            })}
            isTertiary
          />
        </LayoutWithNavbar>
      );
    }
}

export default injectIntl(SellFund);
