import { API } from 'shared/services/api';
import { commonRequest } from 'shared/services/apiCaller';
import { ACTION_TYPE } from './actionTypes';

const getRiskQuestionPending = ({
  type: ACTION_TYPE.RISK_PROFILE_QUESTION_PENDING,
});

const getRiskQuestionSuccess = data => ({
  type: ACTION_TYPE.RISK_PROFILE_QUESTION_SUCCESS,
  questions: data.questions,
});

const getRiskQuestionFailure = error => ({
  type: ACTION_TYPE.RISK_PROFILE_QUESTION_FAILURE,
  error,
});

const getRiskQuestions = () => (dispatch) => {
  dispatch(getRiskQuestionPending);
  commonRequest(API.RISK_PROFILE_QUESTION, 'GET', { channel: 'sinarmas' }).then(
    result => dispatch(getRiskQuestionSuccess(result)),
  ).catch(
    error => dispatch(getRiskQuestionFailure(error)),
  );
};

const submitRiskAnswerPending = ({
  type: ACTION_TYPE.RISK_PROFILE_SUBMIT_ANSWER_PENDING,
});

const submitRiskAnswerSuccess = data => ({
  type: ACTION_TYPE.RISK_PROFILE_SUBMIT_ANSWER_SUCCESS,
  payload: {
    score: data.totalScore,
    profile: data.profile,
    details: data.details,
  },
});

const submitRiskAnswerFailure = error => ({
  type: ACTION_TYPE.RISK_PROFILE_SUBMIT_ANSWER_FAILURE,
  error,
});

const submitRiskAnswers = answers => (dispatch) => {
  dispatch(submitRiskAnswerPending);
  commonRequest(API.RISK_PROFILE_SCORE, 'POST',
    {
      age: 30,
      channel: 'sinarmas',
      name: 'Mai Lam',
      'risk-choices': answers,
      user: '1d50ce00-903e-4296-a8c6-d747d6bcba8f',
    }).then(
    (result) => {
      dispatch(submitRiskAnswerSuccess(result));
    },
  ).catch(
    error => dispatch(submitRiskAnswerFailure(error.message)),
  );
};

const riskProfile = {
  getRiskQuestions,
  submitRiskAnswers,
};

export default riskProfile;
