import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './NWSlider.scss';

const NWSlider = ({
  min, max, defaultValue, onChange,
}) => (
  <Slider min={min} max={max} defaultValue={defaultValue} onChange={onChange} />
);

NWSlider.propTypes = {
  /** slider min value */
  min: PropTypes.number,
  /** slider max value */
  max: PropTypes.number,
  /** slider default value */
  defaultValue: PropTypes.number,
  /** slider on change */
  onChange: PropTypes.func,
};

NWSlider.defaultProps = {
  min: 0,
  max: 0,
  defaultValue: 0,
  onChange: () => {},
};

export default NWSlider;
