import React, { Component } from 'react';
import IconDone from 'assets/images/iconDone@2x.png';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button } from 'components';
import { PATH } from 'shared/constant';
import './OrderReceived.scss';

class OrderReceived extends Component {
  render() {
    const { intl } = this.props;

    return (
      <div className="nw-layout__page nw-page-orderReceived">
        <img src={IconDone} alt="Done" />
        <h2><FormattedMessage id="orderReceived.weReceived" /></h2>
        <p><FormattedMessage id="orderReceived.weCalculate" /></p>
        <span />
        <Button value={intl.formatMessage({ id: 'orderReceived.backToHome' })} onButtonClick={() => this.props.history.push(PATH.HOME)} />
      </div>
    );
  }
}

export default injectIntl(OrderReceived);
