/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './FundFactsheet.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Swipeable } from 'react-swipeable';
import { PATH } from 'shared/constant';
import {
  Navbar, LayoutWithNavbar, Downloads, FundSummary, DonutChartList, ItemList, Button,
} from 'components';

import Performance from './Performance';

const FundDescription = () => (
  <>
    <section>
      <b>NAV: 5,6000</b>
      <span>(↑ 4.06%)</span>
      <h4>14/07/2019</h4>
    </section>
    <p>
    NW Money Market is suitable for short-term investor who wants an optimum return on investment in money market instruments and / or debt securities.
    </p>
  </>
);

class FundFactsheet extends Component {
    state = {
      fundFactValue: {
        title: '',
        category: {
          label: '',
        },
      },
      isPerformance: false,
    }

    componentDidMount() {
      const { catalogue, history: { location } } = this.props;
      const { pathname } = location;
      const fundFactValue = catalogue.find(x => x.id === pathname.split('/fundfactsheet/')[1]);
      this.setState({ fundFactValue });

      const App = document.querySelector('.App');
      App.addEventListener('click', this.hideNavOnClickOutside);
    }

    componentWillUnmount() {
      const App = document.querySelector('.App');
      App.removeEventListener('click', this.hideNavOnClickOutside);
    }

    selectImage = (id) => {
      switch (id) {
        case '1':
          return 'eggs@2x.png';
        case '2':
          return 'graph@2x.png';
        case '3':
          return 'money@2x.png';
        default:
          return 'hands@2x.png';
      }
    }

    onBackButton = () => {
      this.props.history.goBack();
    }

    onShowPerformance = () => {
      this.setState((state) => {
        if (state.isPerformance) {
          return { isPerformance: false };
        }
        return { isPerformance: true };
      });
    };

    hideNavOnClickOutside = (e) => {
      if (!this.nav.contains(e.target) && !e.target.classList.contains('nw-fund-factsheet-page__performance')) {
        this.setState({
          isPerformance: false,
        });
      }
    };

    render() {
      const { fundFactValue: { title, category, id }, isPerformance } = this.state;
      const { fees, topFunds, topSectors } = this.props;
      return (
        <>
          <LayoutWithNavbar
            header={(
              <Navbar
                title={title}
                leftButton={<FontAwesomeIcon icon="chevron-left" />}
                onClickLeft={this.onBackButton}
              />
            )}
          >

            <div className="nw-fund-factsheet-page">
              <FundSummary
                image={this.selectImage(id)}
                category={category.label}
                fundName={title}
                description={<FundDescription />}
                fundButton={<Button isFullWidth value="Buy Now" isSecondary onButtonClick={() => this.props.history.push(PATH.BUY_FUND)} />}
              />
              <DonutChartList />
              <Downloads />
              <DonutChartList
                subTitle="Top 5 Holdings"
                isListBelow
                colors={['#f45f32', '#ff9500', '#fca33b', '#ffcc00', '#fcd53b']}
                data={topFunds}
              />
              <DonutChartList
                subTitle="Top 5 Sectors"
                isListBelow
                colors={['#3442ff', '#3485ff', '#34a7ff', '#34c9ff', '#34ebff']}
                data={topSectors}
              />
              <ItemList subTitle="Fees" listObj={fees} />
              <Swipeable
                onSwipedUp={() => {
                  this.setState({ isPerformance: true });
                }}
              >
                <div
                  className="nw-fund-factsheet-page__performance"
                  onClick={this.onShowPerformance}
                >
                  <span onClick={this.onShowPerformance} />
                  <p>
                  Performance and return
                  </p>
                </div>
              </Swipeable>
              <Swipeable
                onSwipedDown={() => {
                  this.setState({ isPerformance: false });
                }}
              >
                <div className={`nw-fund-factsheet-page__child ${isPerformance ? 'nw-fund-factsheet-page__child--on' : ''}`} ref={e => this.nav = e}>
                  <Performance />
                </div>
              </Swipeable>
            </div>
          </LayoutWithNavbar>
        </>
      );
    }
}

FundFactsheet.propTypes = {
  // List of catalogue
  catalogue: PropTypes.arrayOf(PropTypes.object),
  // List of Fees
  fees: PropTypes.arrayOf(PropTypes.object),
  //
  topFunds: PropTypes.arrayOf(PropTypes.object),
  //
  topSectors: PropTypes.arrayOf(PropTypes.object),
};

FundFactsheet.defaultProps = {
  catalogue: [],
  topFunds: [
    { name: 'Indah Kiat Pulp & Paper Tbk', value: 28 },
    { name: 'Minna Padi Investama Sekuritas Tbk.', value: 24 },
    { name: 'Sukuk Mudharabah Lontar Papyrus…', value: 20 },
    { name: 'Obligasi Berkelanjutan III WOM…', value: 18 },
    { name: 'Obligasi Subordinasi Bank Capital', value: 10 },
  ],
  topSectors: [
    { name: 'Pasar Uang', value: 28 },
    { name: 'Keuangan', value: 24 },
    { name: 'Industri Dasar', value: 20 },
    { name: 'Properti & Konstruksi', value: 18 },
    { name: 'Perdagangan & Jasa', value: 10 },
  ],
  fees: [{
    label: 'Subscription Fee',
    value: '0%',
  }, {
    label: 'Redemption Fee',
    value: '0%',
  }, {
    label: 'Investment Manager Fee',
    value: 'Max 1.00% p.a.',
  }, {
    label: 'Custodian Bank Fee',
    value: 'Max 0.25% p.a.',
  }],
};

const mapStatetoProps = state => ({
  catalogue: state.catalogue,
});

export default connect(mapStatetoProps, () => ({}))(FundFactsheet);
