import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars, faEllipsisH, faArrowUp, faClipboard, faChevronRight, faChevronLeft, faHome, faCheckCircle, faChevronUp, faChevronDown, faCheck, faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import { AutoUpdatePopup } from 'components';
import { isMobile } from 'react-device-detect';
import Routes from './Routes';
import ConnectedIntlProvider from './shared/utils/ConnectedIntlProvider';
import store from './shared/redux';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

library.add(faBars, faEllipsisH, faArrowUp, faClipboard, faChevronRight, faChevronLeft, faHome, faCheckCircle, faChevronUp, faChevronDown, faCheck, faAngleRight);

class App extends Component {
  componentDidMount() {
    if (isMobile) document.body.style.background = '#3485ff';
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedIntlProvider>
          <div className="App">
            <div className={`App__body ${!isMobile ? 'App__body--desktop' : ''}`}>
              <ToastContainer />
              <Routes />

              {/* Auto update Popup for PWA when there is new software update */}
              <AutoUpdatePopup />
            </div>
          </div>
        </ConnectedIntlProvider>
      </Provider>
    );
  }
}

export default App;
