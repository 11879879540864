import React, { Fragment, Component } from 'react';
import { OptionBox } from 'components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import './FilterBox.scss';

class FilterBox extends Component {
  static propTypes = {
    /** box title */
    title: PropTypes.string,
    /** box options */
    options: PropTypes.arrayOf(PropTypes.string),
    /** box type */
    type: PropTypes.string,
    /** select event */
    onSelect: PropTypes.func,
    /** default value */
    defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  };

  static defaultProps = {
    title: '',
    options: [],
    type: 'checkbox',
    onSelect: () => {},
    defaultValue: [],
  };

  state = {
    currentSelect: [],
  };

  componentDidMount() {
    const { defaultValue } = this.props;

    this.setState({
      currentSelect: defaultValue,
    });
  }

  handleSelect = (e) => {
    const { onSelect } = this.props;
    const { currentSelect } = this.state;

    if (e.target.textContent === currentSelect) {
      this.setState({
        currentSelect: null,
      });
    } else {
      this.setState({
        currentSelect: e.target.textContent,
      });
    }
    onSelect(e.target.textContent);
  };

   handleSelectOption = (e) => {
     let temp;
     const {
       setFilter, onSelect, type, name,
     } = this.props;
     const { currentSelect } = this.state;

     if (type === 'radio') {
       temp = [e];
     } else {
       temp = currentSelect;

       if (temp.indexOf(e) === -1) {
         temp.push(e);
       } else {
         temp.splice(temp.indexOf(e), 1);
       }
     }
     this.setState({
       currentSelect: temp,
     });

     const filter = {};
     filter[name] = temp;
     setFilter(filter); // for parents wrapper
     onSelect(e);
   };

   render() {
     const {
       title, options, type, intl,
     } = this.props;

     return (
       <div className="filter-box">
         {title && <h3>{title}</h3>}
         <div className="filter-box__options">
           {
              type === 'horizontal'
                ? (
                  <div className="filter-box__options--horizontal">
                    {options && options.map(item => (
                      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                      <span
                        key={item}
                        onClick={e => this.handleSelect(e)}
                        className={item === this.state.currentSelect ? 'filter-box__option--active' : ''}
                      >
                        {item}
                      </span>
                    ))}
                  </div>
                )
                : (
                  <Fragment>
                    {options && options.map(item => (
                      <OptionBox
                        key={item}
                        onSelect={this.handleSelectOption}
                        description={intl.formatMessage({ id: item })}
                        value={item}
                        type={type}
                        name={_.camelCase(title)}
                        defaultChecked={this.state.currentSelect.includes(item)}
                      />
                    ))}
                  </Fragment>
                )
            }
         </div>
       </div>
     );
   }
}

export default injectIntl(FilterBox);
