import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LayoutWithNavbar, Navbar, InputGroup,
} from 'components';
import { injectIntl } from 'react-intl';
import { PATH } from 'shared/constant';
import './OTP.scss';


class OTP extends Component {
    onBackButton = () => {
      this.props.history.goBack();
    };

    render() {
      const { intl } = this.props;
      return (
        <LayoutWithNavbar
          className="nw-page-otp"
          header={(
            <Navbar
              title={intl.formatMessage({ id: 'otp.confirmYourPin' })}
              onClickLeft={this.onBackButton}
              leftButton={<FontAwesomeIcon icon="chevron-left" />}
            />
                )}
        >
          <p>
            Enter the 6 digit code sent to your
            mobile number ****6657
          </p>
          <InputGroup digits={6} onComplete={() => this.props.history.push(PATH.ORDER_RECEIVED)} />
          <button type="button">{intl.formatMessage({ id: 'otp.resendCode' })}</button>
        </LayoutWithNavbar>
      );
    }
}

export default injectIntl(OTP);
