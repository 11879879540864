import _ from 'lodash';
import { SUCCESS_CODE } from 'shared/services/api';

const requestApi = (
  endpoint,
  method = 'GET',
  body = null,
  header = null,
) => {
  const options = {
    method,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (body !== null) {
    if (method === 'GET') {
      endpoint += (endpoint.indexOf('?') === -1 ? '?' : '&')
                + Object.keys(body)
                  .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(body[k])}`)
                  .join('&');
    } else {
      options.body = JSON.stringify(body);
    }
  }

  if (header !== null) {
    Object.keys(header).forEach((key) => {
      options.headers[key] = header[key];
    });
  }

  return fetch(`${process.env.REACT_APP_API_DOMAIN}/${endpoint}`, options)
    .then((response) => {
      if (SUCCESS_CODE.indexOf(response.status) > -1) {
        return response.json();
      }
      throw new Error(response.message || 'Something went wrong');
    })
    .catch((error) => {
      console.log('Error in fetch : ', error);
      return {
        code: 405,
        message: _.isObject(error) ? error.message : error,
      };
    });
};

const commonRequest = (
  endpoint,
  method = 'GET',
  body = null,
  header = null,
) => requestApi(
  endpoint,
  method,
  body,
  header,
);

// eslint-disable-next-line import/prefer-default-export
export { commonRequest };
