import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './NWLineChartWithFilter.scss';
import { Card, NWLineChart, Spin } from 'components';
import FilterButton from '../../FilterButton/FilterButton';

const NWLineChartWithFilter = ({
  data, listKey, filterList, isFilter, headerTitle, isLegend, activeDot, customTooltipValue, customTooltipLabel,
}) => {
  const [loading, setLoading] = useState(false);

  const onFilterSelect = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return (
    <Card header={headerTitle} className="nw-card--none-padding-side">
      <div className="nw-line-chart-filter">
        {isFilter ? <FilterButton filterList={filterList} onButtonClick={onFilterSelect} /> : <></>}
        <div className="nw-line-chart-filter__chart-area">
          <Spin spinning={loading}>
            {!loading
            && (
            <NWLineChart
              data={data}
              isLegend={isLegend}
              listKey={listKey}
              activeDot={activeDot}
              customTooltipValue={customTooltipValue}
              customTooltipLabel={customTooltipLabel}
            />
            )
            }
          </Spin>
        </div>
      </div>
    </Card>
  );
};

NWLineChartWithFilter.propTypes = {
  // The source data which each element is an object.
  data: PropTypes.arrayOf(PropTypes.object),
  // The number of line (including stroke colors and data key) which will render on the chart
  listKey: PropTypes.arrayOf(PropTypes.object),
  // The list of filter
  filterList: PropTypes.arrayOf(PropTypes.string),
  // Turn the filter on or off
  isFilter: PropTypes.bool,
  // The header title of card containter
  headerTitle: PropTypes.string,
  // display legend or not
  isLegend: PropTypes.bool,
  // The active dot is shown when a user enters a line chart and this chart has tooltip.
  activeDot: PropTypes.objectOf(PropTypes.string),
  // Custom value for Tool tip
  customTooltipLabel: PropTypes.string,
  // Custom label for Tool tip
  customTooltipValue: PropTypes.objectOf(PropTypes.string),
};

NWLineChartWithFilter.defaultProps = {
  data: [
    {
      name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
    },
    {
      name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    },
    {
      name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
    },
    {
      name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    },
    {
      name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
    },
    {
      name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    },
    {
      name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    },
  ],
  listKey: [{
    stroke: '#454545',
    dataKey: 'pv',
  }],
  filterList: ['3m', '6m', '12m', '3y', '5y'],
  isFilter: true,
  headerTitle: '',
  isLegend: false,
  activeDot: {},
  customTooltipLabel: '25/07/2019',
  customTooltipValue: {
    label: 'Value: 18.6119',
    value: '(↑ 6.04%)',
  },
};

export default NWLineChartWithFilter;
