import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import utilitiesService from 'shared/utils/utilitiesService';
import './InputGroup.scss';

class InputGroup extends Component {
  constructor() {
    super();
    this.state = {
      code: [],
    };
    this.inputs = [];
  }

  handleInput = (e) => {
    const { value } = e.target;

    if (!utilitiesService.isNumeric(value)) e.target.value = '';

    if (value.length > 1) {
      e.target.value = value[0];
    }
  };

  handleInputComplete = () => {
    const { onComplete, digits } = this.props;
    const { code } = this.state;
    if (code.filter(item => !_.isEmpty(item)).length === digits) {
      onComplete();
    }
  };

  handleTyping = (e) => {
    // eslint-disable-next-line radix
    const currentIndex = parseInt(e.target.dataset.index);
    const { digits } = this.props;
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newCode = [...this.state.code];

    if (!utilitiesService.isNumeric(e.target.value)) return;

    if (_.isEmpty(e.target.value)) {
      if (currentIndex > 0) {
        this.inputs[currentIndex - 1].focus();
        this.inputs[currentIndex - 1].select();
      }
    } else if (currentIndex < digits - 1) {
      this.inputs[currentIndex + 1].focus();
      this.inputs[currentIndex + 1].select();
    }

    newCode[currentIndex] = e.target.value;
    this.setState({
      code: newCode,
    }, () => this.handleInputComplete());
  };

  renderInputs = () => {
    const { digits } = this.props;
    const inputs = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < digits; i++) {
      inputs.push(
        <input
          type="number"
          key={i}
          data-index={i}
          onKeyUp={this.handleInput}
          onInput={this.handleTyping}
          ref={e => this.inputs[i] = e}
          inputMode="decimal"
          pattern="\d*"
        />,
      );
    }
    return inputs;
  };

  render() {
    return (
      <div className="nw-input-group">
        {this.renderInputs()}
      </div>
    );
  }
}

InputGroup.propTypes = {
  /** number of inputs */
  digits: PropTypes.number,
  /** action on completed */
  onComplete: PropTypes.func,
};

InputGroup.defaultProps = {
  digits: 1,
  onComplete: () => {},
};

export default InputGroup;
