import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { injectIntl } from 'react-intl';
import {
  LayoutWithNavbar, Navbar, SubTitle, ItemList,
  NWLineChartWithFilter, Button,
} from 'components';
import { PATH } from 'shared/constant';
import MyPortfolio from '../Homepage/Tabs/MyPortfolio';

class PortfolioDetails extends Component {
  equities = [
    {
      id: 1,
      label: 'NW Equity',
      value: 'Rp. 12,375,644,361',
    },
    {
      id: 2,
      label: 'NW Equity II',
      value: 'Rp. 31,035,988,850',
    },
  ];

  fixedIncome = [
    {
      label: 'NW Fixed Income II',
      value: 'Rp. 23,661,336,935',
    },
  ];

  moneyMarket = [
    {
      label: 'NW Money Market',
      value: 'Rp. 25,572,791,241',
    },
  ];

  data = [
    {
      name: 'J', pv: 400,
    },
    {
      name: 'F', pv: 300,
    },
    {
      name: 'M', pv: 500,
    },
    {
      name: 'A', pv: 100,
    },
    {
      name: 'M', pv: 600,
    },
    {
      name: 'J', pv: 200,
    },
    {
      name: 'J', pv: 100,
    },
    {
      name: 'A', pv: 400,
    },
    {
      name: 'S', pv: 600,
    },
    {
      name: 'O', pv: 800,
    },
    {
      name: 'N', pv: 100,
    },
  ];

  listKey = [{
    stroke: '#454545',
    dataKey: 'pv',
  }];

  onBackButton = () => {
    this.props.history.goBack();
  }

  render() {
    const { intl, history } = this.props;
    return (
      <LayoutWithNavbar
        header={(
          <Navbar
            title={intl.formatMessage({ id: 'portfolio.portfolioDetails' })}
            leftButton={<FontAwesomeIcon icon="chevron-left" />}
            onClickLeft={this.onBackButton}
            // rightButton={<FontAwesomeIcon icon="ellipsis-h" />}
          />
        )}
      >
        <MyPortfolio
          isButtonHidden
          showIcon={false}
          history={history}
          button={(
            <Button isOutline isFullWidth value={intl.formatMessage({ id: 'portfolio.deviation_analysis' })} onButtonClick={() => history.push(PATH.DEVIATION_ANALYSIS)} />
          )}
        />
        <SubTitle label={intl.formatMessage({ id: 'portfolio.performance' })} />
        <NWLineChartWithFilter data={this.data} listKey={this.listKey} activeDot={{ stroke: '#3485ff', fill: '#3485ff' }} />

        <SubTitle label={intl.formatMessage({ id: 'portfolio.portfolio_holdings' })} />
        <ItemList isPortfolio color="#FCD53B" subTitle={intl.formatMessage({ id: 'portfolio.equities' })} value="Rp. 43,411,633,296" listObj={this.equities} isLink />
        <ItemList isPortfolio color="#F45F32" subTitle={intl.formatMessage({ id: 'portfolio.fixed_income' })} value="Rp. 23,661,336,935" listObj={this.fixedIncome} />
        <ItemList isPortfolio color="#FCA33B" subTitle={intl.formatMessage({ id: 'portfolio.money_market' })} listObj={this.moneyMarket} />

      </LayoutWithNavbar>
    );
  }
}

export default injectIntl(PortfolioDetails);
