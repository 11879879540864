/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  MutualFund, Navbar, LayoutWithNavbar,
  SubTitle, Card, Button, Agreement, InputBox,
  // SelectBox,
} from 'components';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { injectIntl } from 'react-intl';
import numeral from 'numeral';
import './BuyFund.scss';
import { PATH } from 'shared/constant';
import { savePayment } from 'shared/redux/actions';

import utilitiesService from 'shared/utils/utilitiesService';

class BuyFund extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalAmount: 0,
      defaultFee: 0.02,
      amount: 0,
      isAgree: false,
      errorStack: [],
      // source: '',
    };
  }

    onBackButton = () => {
      this.props.history.goBack();
    };

    handleTotalAmount = (e) => {
      utilitiesService.dismissAllToast();
      this.checkValid('amount', e.target.value);

      const { defaultFee } = this.state;
      const amount = numeral(e.target.value).format('0');
      const totalAmount = (1 + defaultFee) * Number(amount);
      this.setState({ totalAmount, amount, errorStack: [] });
    };

    // handleSelectSource = (e) => {
    //   this.setState({ source: e });
    // }

    checkValid = (key, value) => {
      const keyList = ['amount'];
      let { errorStack } = this.state;
      if (key) {
        if (value === 0) {
          errorStack.push(key);
        } else {
          errorStack = errorStack.filter(x => x !== key);
        }
        this.setState({ errorStack: _.uniqBy(errorStack) });
      } else {
        for (let i = 0; i < keyList.length; i += 1) {
          if (this.state[keyList[i]] === 0) {
            errorStack.push(keyList[i]);
          } else {
            errorStack = errorStack.filter(x => x !== keyList[i]);
          }
        }
        this.setState({ errorStack: _.uniqBy(errorStack) });
      }
    }

    handlePaymentSummary = () => {
      const { onSavePayment, intl, history } = this.props;
      const {
        amount, totalAmount, isAgree,
        // source,
      } = this.state;
      // if (isAgree && source !== '') {
      if (isAgree) {
        const listOfPayment = [{
          label: intl.formatMessage({ id: 'buyFund.purchasing' }),
          value: 'Capital Protected Fund V',
        }, {
          label: intl.formatMessage({ id: 'buyFund.purchaseAmount' }),
          value: `Rp. ${numeral(amount).format('0,0')}`,
        }, {
          label: intl.formatMessage({ id: 'buyFund.transactionFee' }),
          value: '0',
        }, {
          label: intl.formatMessage({ id: 'buyFund.subscriptionFee' }),
          value: `Rp. ${numeral(amount * 0.02).format('0,0')}`,
        }, {
          label: intl.formatMessage({ id: 'buyFund.totalAmount' }),
          value: `Rp. ${numeral(totalAmount).format('0,0')}`,
        },
        // {
        //   label: intl.formatMessage({ id: 'buyFund.sourceOfFunds' }),
        //   value: source,
        // },
        {
          label: intl.formatMessage({ id: 'buyFund.paymentMethod' }),
          value: intl.formatMessage({ id: 'buyFund.bankTransfer' }),
        }];
        onSavePayment(listOfPayment);
        history.push(PATH.PAYMENT);
      }
    }

    onSubmitBuyFund = () => {
      this.checkValid();

      const { errorStack } = this.state;
      if (errorStack.length === 0) {
        this.handlePaymentSummary();
      } else {
        const errorMessage = 'You did not meet the 50k minimum purchase amount. Please enter again.';
        utilitiesService.displayErrorMessage(errorMessage);
      }
    }

    render() {
      const { intl, history } = this.props;
      const {
        totalAmount, amount, isAgree, errorStack,
        // source,
      } = this.state;
      const value = {
        id: '2',
        status: 'Low Risk',
        banner: 'graph@2x.png',
        isInvest: true,
        category: {
          label: 'Money Market',
          href: '#',
        },
        title: 'Capital Protected Fund V',
        listOfValue: [{
          value: 'Rp. 500 k',
          descr: 'Minimum',
        }, {
          value: '18.6001',
          descr: 'NAV',
        }, {
          value: '14/07/2019',
          descr: 'Date',
          isUnCheck: true,
        }],
      };

      // const sourceFund = [
      //   intl.formatMessage({ id: 'buyFund.revenue' }),
      //   intl.formatMessage({ id: 'buyFund.businessProfit' }),
      //   intl.formatMessage({ id: 'buyFund.savingInterest' }),
      //   intl.formatMessage({ id: 'buyFund.legacy' }),
      //   intl.formatMessage({ id: 'buyFund.fundFromParents' }),
      //   intl.formatMessage({ id: 'buyFund.grant' }),
      //   intl.formatMessage({ id: 'buyFund.fundFromHusband' }),
      //   intl.formatMessage({ id: 'buyFund.drawing' }),
      // ];

      return (
        <LayoutWithNavbar
          header={(
            <Navbar
              title={intl.formatMessage({ id: 'buyFund.buyFund' })}
              leftButton={<FontAwesomeIcon icon="chevron-left" />}
              onClickLeft={this.onBackButton}
            />
                )}
          className="nw-page-buyFund"
        >
          <MutualFund
            history={history}
            id={value.id}
            showInvest
            status={value.status}
            title={value.title}
            category={value.category}
            description={value.description}
            listOfvalue={value.listOfValue}
            banner={value.banner}
            buttonValue="Fund Factsheet"
            onButtonClick={() => {
              history.push(`${PATH.FUND_FACTSHEET}/1`);
            }}
          />
          <Card
            header={(
              <SubTitle label={intl.formatMessage({ id: 'buyFund.inputPurchasingDetails' })} />
                )}
          >
            <InputBox
              type="tel"
              title={intl.formatMessage({ id: 'buyFund.amount' })}
              customClass={errorStack.includes('amount', amount) ? 'nw-input-box--invalid' : ''}
              currency="Rp."
              onInputChange={this.handleTotalAmount}
              value={numeral(amount).format('0,0')}
            />
            <InputBox
              title={intl.formatMessage({ id: 'buyFund.subscriptionFee' })}
              isDisabled
              value="2.00%"
            />
            <InputBox
              title={intl.formatMessage({ id: 'buyFund.totalAmount' })}
              isDisabled
              currency="Rp."
              value={numeral(totalAmount).format('0,0')}
            />
            {/* <SelectBox
              title={intl.formatMessage({ id: 'buyFund.sourceOfFund' })}
              onSelect={this.handleSelectSource}
              defaultValue={intl.formatMessage({ id: 'buyFund.pleaseSelect' })}
              dataList={sourceFund}
            /> */}
          </Card>
          <Agreement
            agreement={intl.formatMessage({ id: 'buyFund.iHaveReadAndUnderstood' })}
            prospectus={intl.formatMessage({ id: 'buyFund.viewProspectus' })}
            onCheckAgree={(isChecked) => {
              this.setState({ isAgree: isChecked });
            }}
          />
          <Button
            isSecondary
            value={intl.formatMessage({ id: 'buyFund.buyFund' })}
            onButtonClick={this.onSubmitBuyFund}
            isDisabled={!(isAgree)}
          />
        </LayoutWithNavbar>
      );
    }
}

const mapDispatchToProps = ({
  onSavePayment: savePayment,
});

export default connect(() => ({}), mapDispatchToProps)(injectIntl(BuyFund));
