import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { FilterBox, Button } from 'components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './CatalogueFilter.scss';

class CatalogueFilter extends Component {
  static propTypes = {
    /** filter visible */
    visible: PropTypes.bool,
    /** filter onOK */
    onOk: PropTypes.func,
    filters: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    visible: false,
    onOk: () => {},
    filters: [
      {
        title: 'catalogue.fundCategory',
        options: [
          'catalogue.equity',
          'catalogue.balanced',
          'catalogue.fixedIncome',
          'catalogue.moneyMarket',
        ],
        default: [
          'catalogue.equity',
          'catalogue.balanced',
          'catalogue.fixedIncome',
          'catalogue.moneyMarket',
        ],
        name: 'category',
      },
      // {
      //   title: 'Islamic Only',
      //   options: [
      //     'Yes',
      //     'No',
      //   ],
      //   default: 'No',
      //   type: 'radio',
      // },
      {
        title: 'catalogue.fundTypes',
        options: [
          'catalogue.openForSubscription',
          'catalogue.closedForSubscription',
        ],
        default: 'catalogue.openForSubscription',
        type: 'radio',
        name: 'type',
      },
    ],
  };

  constructor() {
    super();
    this.filter = React.createRef();
  }

  componentDidMount() {
    const App = document.querySelector('.App');
    App.addEventListener('click', this.hideOnClickOutside);
    const { setFilter, filters } = this.props;
    setFilter({
      category: filters[0].default,
      type: filters[1].default,
    });
  }

  componentWillUnmount() {
    const App = document.querySelector('.App');
    App.removeEventListener('click', this.hideOnClickOutside);
  }

  hideOnClickOutside = (e) => {
    const { visible, onOk } = this.props;
    if (!this.filter.current.contains(e.target) && visible) {
      onOk();
    }
  };

  render() {
    const {
      visible, onOk, intl, setFilter, filters,
    } = this.props;
    const filterClass = classNames({
      'catalogue-filter': true,
      'catalogue-filter--open': visible,
    });

    return (
      <div className={filterClass}>
        <div className="catalogue-filter__container" ref={this.filter}>
          {filters && filters.map(item => (
            <FilterBox
              key={item.title}
              title={intl.formatMessage({ id: item.title })}
              options={item.options}
              type={item.type}
              defaultValue={item.default}
              setFilter={setFilter}
              name={item.name}
            />
          ))}
          <Button value={intl.formatMessage({ id: 'catalogue.ok' })} onButtonClick={onOk} />
        </div>
      </div>
    );
  }
}

export default injectIntl(CatalogueFilter);
