/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import classNames from 'classnames';
import './SelectBox.scss';

import SelectIcon from '../../assets/icons/exportDown.png';

class SelectBox extends Component {
  state = {
    showList: false,
    selected: this.props.defaultValue,
    inputValue: this.props.defaultValue,
    selectedValue: true,
    isFocus: false,
  };

  handleSelect = (e) => {
    this.props.onSelect(e);
    this.setState({
      selected: e,
      showList: false,
      inputValue: e,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      inputValue: e.target.value,
      showList: true,
      selectedValue: false,
    });
  };

  handleInputBlur = (notFound) => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      inputValue: notFound ? this.props.defaultValue : this.state.inputValue,
      selectedValue: true,
      isFocus: false,
    });
  };

  render() {
    const { title, dataList, intl } = this.props;
    const {
      showList, inputValue, selectedValue, isFocus,
    } = this.state;
    const filteredList = dataList.filter(item => selectedValue || _.toLower(item).includes(_.toLower(inputValue)));
    const selectBoxClass = classNames({
      'select-box--focused': isFocus,
    });

    return (
      <>
        <div className={`select-box ${selectBoxClass}`}>
          <h3>{title}</h3>
          <div
            className="select-box__current"
            tabIndex={1}
            onClick={() => {
              // eslint-disable-next-line react/no-access-state-in-setstate
              this.setState({ showList: !this.state.showList });
            }}
            onBlur={() => {
              this.setState({ showList: false });
            }}
          >
            <div className="select-box__value">
              <input
                className="select-box__input"
                onChange={() => {}}
                type="radio"
                checked="checked"
                name="nw"
              />
              <input
                className="select-box__input-text"
                type="text"
                value={inputValue}
                onChange={this.handleInputChange}
                onBlur={() => this.handleInputBlur(_.isEmpty(filteredList))}
                onFocus={() => this.setState({ isFocus: true })}
              />
            </div>
            <img className={`select-box__icon ${showList ? 'select-box__icon--active' : ''}`} src={SelectIcon} alt="Arrow Icon" />
          </div>
          <ul className={`select-box__list ${showList ? 'select-box__list--active' : ''}`}>
            {!_.isEmpty(filteredList)
              ? filteredList.map(value => (
                // use mousedown instead on click to avoid conflict with handleInputBlur
                <li
                  key={value}
                  onMouseDown={() => this.handleSelect(value)}
                >
                  <label
                    className={`select-box__option ${value === this.state.selected ? 'select-box__option--active' : ''}`}
                    htmlFor={value}
                  >
                    {value}
                  </label>
                </li>
              ))
              : (
                <li>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label
                    className="select-box__option select-box__option--disabled"
                  >
                    {intl.formatMessage({ id: 'selectBox.notFound' })}
                  </label>
                </li>
              )
              }
          </ul>
        </div>
      </>
    );
  }
}

SelectBox.propTypes = {
  // select list
  dataList: PropTypes.arrayOf(PropTypes.string),
  // title of select
  title: PropTypes.string,
  // on select item
  onSelect: PropTypes.func,
};

SelectBox.defaultProps = {
  dataList: [],
  title: '',
  onSelect: () => {},
};

export default injectIntl(SelectBox);
