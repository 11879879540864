import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Button.scss';

/**
 * Button
 * @param {id, name, value, onButtonClick, isSecondary, isOutline, isDisabled} props
 * global buttons
 */

const Button = (props) => {
  const {
    id, name, value, onButtonClick, isSecondary, isTertiary, isOutline, isDisabled, isSmall, isBig, isFilter, customClassName, isFullWidth, isComplete,
  } = props;
  const classes = classNames({
    'nw-button': true,
    'nw-button--primary': !isSecondary,
    'nw-button--secondary': isSecondary,
    'nw-button--tertiary': isTertiary,
    'nw-button--outline': isOutline,
    'nw-button--disabled': isDisabled,
    'nw-button--small': isSmall,
    'nw-button--big': isBig,
    'nw-button--filter': isFilter,
    'nw-button--fullwidth': isFullWidth,
    'nw-button--complete': isComplete,
  });

  return (
    <button type="button" id={id} name={name} onClick={onButtonClick} disabled={isDisabled} className={`${classes} ${customClassName}`}>
      {value}
    </button>
  );
};

Button.propTypes = {
  /** button ID */
  id: PropTypes.string,
  /** button label */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** button name */
  name: PropTypes.string,
  /** button event click */
  onButtonClick: PropTypes.func,
  /** is button secondary (green button) */
  isSecondary: PropTypes.bool,
  /** is button Tertiary (orange button) */
  isTertiary: PropTypes.bool,
  /** is button an outline design */
  isOutline: PropTypes.bool,
  /** is button disabled */
  isDisabled: PropTypes.bool,
  /** is button small */
  isSmall: PropTypes.bool,
  /** is button big */
  isBig: PropTypes.bool,
  /** is button filter */
  isFilter: PropTypes.bool,
  /** Addition class */
  customClassName: PropTypes.string,
  /** */
  isFullWidth: PropTypes.bool,
};

Button.defaultProps = {
  id: null,
  value: 'Button label',
  name: 'Button name',
  onButtonClick: () => {},
  isSecondary: false,
  isTertiary: false,
  isOutline: false,
  isDisabled: false,
  isSmall: false,
  isBig: false,
  isFilter: false,
  customClassName: '',
  isFullWidth: false,
};

export default Button;
