import { ACTION_TYPE } from './actionTypes';

const handleFilterList = (value, title) => (dispatch, getState) => {
  const { filterList } = getState().transaction;
  if (title === 'Date range') {
    const index = filterList.findIndex(x => x.title === title);
    filterList[index].value = value;
  } else {
    const index = filterList.findIndex(x => x.value === value);
    if (index === -1) {
      filterList.push({ value, title });
    } else {
      filterList.splice(index, 1);
    }
  }
  dispatch({
    type: ACTION_TYPE.TRANSACTION_FILTER,
    payload: filterList,
  });
};

export default handleFilterList;
