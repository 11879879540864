import React from 'react';
import PropTypes from 'prop-types';
import CardHeader from './CardHeader';
import './Card.scss';

const Card = ({
  header, children, className, showHeader,
}) => (
  <div className={`nw-card ${className}`}>
    { showHeader && header ? <div className="nw-card__header">{header}</div> : <></> }
    <div className="nw-card__body">
      {children}
    </div>
  </div>
);

Card.propTypes = {
  /** card title */
  header: PropTypes.node,
  /** card body */
  children: PropTypes.node,
  /** card class */
  className: PropTypes.string,
  /** show card header */
  showHeader: PropTypes.bool,
};

Card.defaultProps = {
  header: null,
  children: null,
  className: '',
  showHeader: true,
};

export default Card;
export { CardHeader };
