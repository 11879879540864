import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { handleFilterList } from 'shared/redux/actions';
import {
  LayoutWithNavbar, Navbar, TransactionList,
} from 'components';
import './TransactionHistory.scss';
import { Swipeable } from 'react-swipeable';
import TransactionFilter from './TransactionFilter';

class TransactionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      title: props.intl.formatMessage({ id: 'transaction.displayAll' }, { times: '6 months' }),
    };
    this.handleSwipeRight = this.handleSwipeRight.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      const { intl, filterList, filters } = this.props;
      let title = '';
      let type = ''; const countType = [];
      let status = ''; const countStatus = [];
      let times = '';

      for (let i = 0; i < filterList.length; i += 1) {
        if (filterList[i].title === 'Type') {
          countType.push(filterList[i].value);

          if (countType.length !== filters.find(x => x.title === 'Type').options.length) {
            type = String(countType);
          } else {
            type = 'All';
          }
        }

        if (filterList[i].title === 'Status') {
          countStatus.push(filterList[i].value);
          if (countStatus.length !== filters.find(x => x.title === 'Status').options.length) {
            status = String(countStatus);
          } else {
            status = '';
          }
        }

        if (filterList[i].title === 'Date range') {
          if (filterList[i].value.includes('1')) {
            if (filterList[i].value.includes('M')) {
              times = `${filterList[i].value.split('M')[0]} month`;
            } else {
              times = `${filterList[i].value.split('Y')[0]} year`;
            }
          } else if (filterList[i].value.includes('M')) {
            times = `${filterList[i].value.split('M')[0]} months`;
          } else {
            times = `${filterList[i].value.split('Y')[0]} years`;
          }
        }
      }

      if (type !== 'All' && status !== '') {
        title = intl.formatMessage({ id: 'transaction.displayTypeStatus' }, { times, type, status });
      } else if (type !== 'All' && status === '') {
        title = intl.formatMessage({ id: 'transaction.displayType' }, { times, type });
      } else if (type === 'All' && status !== '') {
        title = intl.formatMessage({ id: 'transaction.displayStatus' }, { times, status });
      } else if (type === 'All' && status === '') {
        title = intl.formatMessage({ id: 'transaction.displayAll' }, { times });
      }

      if (prevState.title !== title) {
        this.setState({ title });
      }
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.filterList.length !== this.props.filterList) {
      return this.props.filterList;
    }
    return null;
  }

  onBackButton = () => {
    this.props.history.goBack();
  };

  handleSwipeRight = (e) => {
    // detect if user swipe on date range
    const isSwipingFilter = e.event.path.filter(item => item.classList && item.classList.contains('filter-box__options--horizontal')).length;

    if (!isSwipingFilter) {
      this.setState({ showFilter: false });
    }
  };

  render() {
    const dataSet = [
      {
        unit: 'Balances',
        value: 'Rp. 25,098,000,000',
        time: '12/07/2019',
        type: 'buy',
        status: 'pending',
      },
      {
        unit: 'Balances II',
        value: 'Rp. 10,000,000',
        time: '01/07/2019',
        type: 'sell',
        status: 'inProcess',
      },
      {
        unit: 'Capital Protected VI',
        value: 'Rp. 15,098,000,000',
        time: '18/05/2019',
        type: 'buy',
        status: 'completed',
      },
      {
        unit: 'Capital Protected II',
        value: 'Rp. 670,000,000',
        time: '10/04/2019',
        type: 'sell',
        status: 'completed',
      }, {
        unit: 'Capital Protected II',
        value: 'Rp. 45,098,000,000',
        time: '12/12/2018',
        type: 'buy',
        status: 'completed',
      }, {
        unit: 'Capital Protected VI',
        value: 'Rp. 60,000,000',
        time: '05/09/2018',
        type: 'sell',
        status: 'completed',
      },
      {
        unit: 'Capital Protected VI',
        value: 'Rp. 670,000,000',
        time: '14/07/2018',
        type: 'buy',
        status: 'completed',
      },
    ];
    const { onHandleFilterList, filters } = this.props;

    return (
      <Swipeable
        onSwipedRight={this.handleSwipeRight}
        onSwipedLeft={() => this.setState({ showFilter: true })}
      >
        <LayoutWithNavbar
          header={(
            <Navbar
              title="Transactions History"
              rightButton="Filter"
              onClickRight={() => this.setState({ showFilter: true })}
              withSideMenu
            />
                )}
        >
          <TransactionList header={this.state.title} data={dataSet} />
        </LayoutWithNavbar>
        <TransactionFilter
          filters={filters}
          onHandleFilterList={onHandleFilterList}
          visible={this.state.showFilter}
          onOk={() => this.setState({ showFilter: false })}
        />
      </Swipeable>
    );
  }
}

const mapStateToProps = state => ({
  filterList: state.transaction.filterList,
  filters: state.transaction.filters,
});

const mapDispatchToProps = ({
  onHandleFilterList: handleFilterList,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TransactionHistory));
