import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import './ProgressBar.scss';

const ProgressBar = ({
  percent, description, isWarning, intl,
}) => {
  const descClass = classNames({
    'nw-progress-bar__description': true,
    'nw-progress-bar__description--warning': isWarning,
  });

  return (
    <div className="nw-progress-bar">
      <div className="nw-progress-bar__percent">
        <FormattedMessage id="progress.percent" values={{ value: <b>{percent}%</b> }} />
      </div>
      <div className="nw-progress-bar__track">
        <div className="nw-progress-bar__progress" style={{ width: `${percent}%` }} />
      </div>
      <div className={descClass}>{description}</div>
    </div>
  );
};

ProgressBar.propTypes = {
  /** progress percentage */
  percent: PropTypes.number,
  /** progress description */
  description: PropTypes.string,
  /** warning description style */
  isWarning: PropTypes.bool,
};

ProgressBar.defaultProps = {
  percent: 0,
  description: '',
  isWarning: false,
};

export default injectIntl(ProgressBar);
