/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import GraphOn from 'assets/icons/graphOn.png';
import GraphOff from 'assets/icons/graphOff.png';

const FundMultiplier = ({ data, defaultActive }) => {
  const [active, setState] = useState(defaultActive);
  const [selectecValue, setValue] = useState(data[defaultActive - 1]);
  const setActive = (index) => {
    setState(index);
    setValue(data[index - 1]);
  };
  const getClass = (index) => {
    if (index === active) {
      return 'nw-button--filter--active';
    }
    return '';
  };
  return (
    <div className="fund-multiplier">
      <div className="fund-multiplier__list">
        {data.map((value, key) => (
          <div className="fund-multiplier__list__item" key={key} onClick={() => setActive(key + 1)}>
            <img src={(key + 1) === active ? GraphOn : GraphOff} alt="" />
            <Button
              customClassName={getClass(key + 1)}
              id={value}
              isFilter
              value={value}
            />
          </div>
        ))}
      </div>
      <div className="fund-multiplier__desc">
        <p>Your funds can grow up to </p>
        <h4>{`${selectecValue} in 13 yrs`}</h4>
      </div>
    </div>
  );
};

FundMultiplier.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  defaultActive: PropTypes.number,
};


FundMultiplier.defaultProps = {
  data: ['2x', '3x', '4x'],
  defaultActive: 2,
};

export default FundMultiplier;
