import React from 'react';
import PropTypes from 'prop-types';
import './Navbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { setOpenMenu } from 'shared/redux/actions';
import {
  Menu,
} from 'components';

const Navbar = ({
  title, leftButton, rightButton, onClickLeft, onClickRight, withSideMenu, openMenu, setMenuVisible,
}) => {
  if (withSideMenu) {
    onClickLeft = () => setMenuVisible(true);
    leftButton = <FontAwesomeIcon icon="bars" />;
  }
  return (
    <nav className="nw-top-nav">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="nw-top-nav__left-button" onClick={onClickLeft}>{leftButton}</div>
      <span className="nw-top-nav__title">{title}</span>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="nw-top-nav__right-button" onClick={onClickRight}>{rightButton}</div>
      {withSideMenu && <Menu visible={openMenu} setVisible={setMenuVisible} />}
    </nav>
  );
};

Navbar.propTypes = {
  /** nav title */
  title: PropTypes.string,
  /** nav left button */
  leftButton: PropTypes.node,
  /** nav right button */
  rightButton: PropTypes.node,
  /** left button click action */
  onClickLeft: PropTypes.func,
  /** right button click action */
  onClickRight: PropTypes.func,
  withSideMenu: PropTypes.bool,
};

Navbar.defaultProps = {
  title: '',
  leftButton: null,
  rightButton: null,
  onClickLeft: null,
  onClickRight: null,
  withSideMenu: false,
};

const mapStateToProps = state => ({
  openMenu: state.toggleMenu.openMenu,
});

const mapDispatchToProps = dispatch => ({
  setMenuVisible: e => dispatch(setOpenMenu(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
