import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './PaymentComplete.scss';
import { injectIntl } from 'react-intl';
import {
  Navbar, LayoutWithNavbar, Button, SubTitle, Card,
} from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PATH } from 'shared/constant';

let x;

class PaymentComplete extends Component {
  state = {
    timer: '--:--:--',
    completed: false,
  };

  componentDidMount() {
    this.getTimer();
  }

  componentWillUnmount() {
    clearInterval(x);
  }

  formatTime = (value) => {
    const str = String(value);
    if (str.length === 1) {
      return `0${str}`;
    }
    return str;
  }

  getTimer = () => {
    const { submissionTime } = this.props;
    const countDownDate = new Date(submissionTime).getTime();
    x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      let timer = `${this.formatTime(hours)}:${this.formatTime(minutes)}:${this.formatTime(seconds)}`;
      this.setState({ timer });

      if (distance < 0) {
        clearInterval(x);
        timer = '00:00:00';
        this.setState({ timer });
      }
    }, 1000);
  }

  onBackButton = () => {
    this.props.history.goBack();
  }

  render() {
    const { intl, history } = this.props;
    const { completed } = this.state;
    return (
      <LayoutWithNavbar
        header={(
          <Navbar
            title={intl.formatMessage({ id: 'paymentComplete.completePayment' })}
            // leftButton={<FontAwesomeIcon icon="chevron-left" />}
            // onClickLeft={this.onBackButton}
          />
                )}
        className="nw-page-payment-complete"
      >
        <Card header={(
          <SubTitle label={intl.formatMessage({ id: 'paymentComplete.completepayment24hours' })} />
                )}
        >
          <h3>Mandiri Investa Pasar Uang</h3>
          <section>
            <p>Bank Mandiri</p>
            <br />
            <p>104-000-445-990-3</p>
            <br />
            <p>Cab. Bursa Efek Indinesia</p>
            <br />
            <p>a/n Reksa Dana Mandiri Investa Pasar Uang</p>
          </section>
          <Button
            value={completed
              ? (
                <>
                  <FontAwesomeIcon icon="check" />
                  &nbsp;
                  {intl.formatMessage({ id: 'paymentComplete.completed' })}
                </>
              )
              : intl.formatMessage({ id: 'paymentComplete.markPaymentAsComplete' })}
            onButtonClick={() => {
              this.setState({ completed: !completed });
            }}
            isFullWidth
            isOutline
            isComplete={completed}
          />
          {!completed ? <h4>{`${intl.formatMessage({ id: 'paymentComplete.timeRemaining' })} ${this.state.timer}`}</h4> : <></>}
        </Card>
        <p>{intl.formatMessage({ id: 'paymentComplete.yourPaymentOrderWillCancelled' })}</p>
        <p className="nw-page-payment-complete__orange">{intl.formatMessage({ id: 'paymentComplete.importantAllpaymentFinal' })}</p>
        <Button
          isPrimary
          value={intl.formatMessage({ id: 'paymentComplete.backToHome' })}
          onButtonClick={() => {
            history.push(PATH.HOME);
          }}
        />
      </LayoutWithNavbar>
    );
  }
}

const mapStateToProps = state => ({
  submissionTime: state.payment.submissionTime,
});

export default connect(mapStateToProps, () => ({}))(injectIntl(PaymentComplete));
