import React from 'react';
import PropTypes from 'prop-types';
import Item from './Item';
import Card from '../Card/Card';
import './ItemList.scss';

const ItemList = props => (
  <Card
    showHeader={props.showHeader}
    header={(
      <Item label={props.subTitle} value={props.value} isPortfolio={props.isPortfolio} color={props.color} />
  )}
  >
    <div className="nw-itemList">
      { props.listObj && props.listObj.map((item, i) => (
        <Item
          key={`item-${i}`}
          id={item.id}
          label={item.label}
          value={item.value}
          isLink={props.isLink}
        />
      ))}
    </div>
  </Card>
);

ItemList.propTypes = {
  /** Card Subtitle */
  subTitle: PropTypes.string,
  /** Card subtitle value */
  value: PropTypes.string,
  /** array of object for list */
  listObj: PropTypes.arrayOf(PropTypes.object),
  /** is the whole list a link */
  isLink: PropTypes.bool,
  /** is Portfolio */
  isPortfolio: PropTypes.bool,
  /** Portfolio color */
  color: PropTypes.string,
};

ItemList.defaultProps = {
  subTitle: '',
  value: '',
  isLink: false,
  listObj: [
    {
      id: null,
      label: 'label 1',
      value: 'value 1',
    },
    {
      id: null,
      label: 'label 2',
      value: 'value 2',
    },
  ],
  isPortfolio: false,
  color: null,
};

export default ItemList;
