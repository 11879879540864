import React from 'react';
// import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Spin } from 'components';
import './AutoUpdatePopup.scss';

const AutoUpdatePopup = () => (
  <div id="update-notification">
    <FormattedMessage id="pwa.newVersion" />
    <Button
      type="button"
      isSecondary
      isSmall
      value={(
        <>
          <Spin spinning />
          <FormattedMessage id="pwa.updateNow" />
        </>
)}
    />
    {/* <FormattedMessage id="pwa.clearData" /> */}
  </div>
);

export default AutoUpdatePopup;
