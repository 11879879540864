import React from 'react';
import PropTypes from 'prop-types';

const HomeHeader = ({
  title, value, percentageChange, valueChange, navbar,
}) => (
  <div className="nw-home-header">
    {navbar}
    { title && <h1>{title}</h1> }
    { value && <div className="nw-home-header__value">{value}</div> }
    <div className="nw-home-header__change">
      { percentageChange && <div className="nw-home-header__change-percent">{percentageChange}</div> }
      { valueChange && <div className="nw-home-header__change-value">{valueChange}</div> }
    </div>
  </div>
);

HomeHeader.propTypes = {
  /** header title */
  title: PropTypes.string,
  /** header investment value */
  value: PropTypes.string,
  /** header investment value change in % */
  percentageChange: PropTypes.node,
  /** header investment value change */
  valueChange: PropTypes.string,
};

HomeHeader.defaultProps = {
  title: '',
  value: '',
  percentageChange: null,
  valueChange: '',
};

export default HomeHeader;
