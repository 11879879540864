import React, { Component } from 'react';
import {
  Card, LayoutWithNavbar, Navbar, Button,
} from 'components';
import { PATH, STORAGE } from 'shared/constant';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import utilitiesService from 'shared/utils/utilitiesService';
import './RiskProfiling.scss';
import RiskScale from './RiskScale';

class RiskProfileResult extends Component {
  componentDidMount() {
    localStorage.removeItem(STORAGE.RISK_EXPIRE);
  }

  onBackButton = () => {
    this.props.history.push(PATH.HOME);
  };

  render() {
    const {
      intl, score, profile, profileDesc,
    } = this.props;
    return (
      <LayoutWithNavbar
        header={(
          <Navbar
            title={intl.formatMessage({ id: 'riskProfile.valueInterpretation' })}
            withSideMenu
          />
            )}
      >
        <RiskScale percent={score} />
        <Card className="risk-profiling-card">
          <h2>{profile && intl.formatMessage({ id: `riskProfile.${profile}` })}</h2>
          <p>
            {profileDesc && intl.formatMessage({ id: utilitiesService.translateProfileDescription(profileDesc) })}
          </p>
          <h4 className="risk-profiling-card__warning">
            {(profile === 'aggressive')
              ? intl.formatMessage({ id: 'riskProfile.thisRiskValidFor1Year' })
              : intl.formatMessage({ id: 'riskProfile.thisRiskValidFor2Years' })}
          </h4>
          <Button
            value={intl.formatMessage({ id: 'risk.reassess' })}
            onButtonClick={() => this.props.history.push(PATH.RISK_QUESTIONS)}
            isOutline
          />
        </Card>
        <Button
          value={intl.formatMessage({ id: 'risk.continue' })}
          onButtonClick={() => this.props.history.push(PATH.CATALOGUE)}
        />
      </LayoutWithNavbar>
    );
  }
}

const mapStateToProps = state => ({
  score: state.riskProfile.score,
  profile: state.riskProfile.profile,
  profileDesc: state.riskProfile.profileDesc,
});

export default connect(mapStateToProps, null)(injectIntl(RiskProfileResult));
