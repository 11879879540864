import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import './Menu.scss';
import { ENGLISH, BAHASA, PATH } from 'shared/constant';
import { switchLanguage, setOpenMenu } from 'shared/redux/actions';

const Menu = ({
  visible, setVisible, location, currentLocale, onSwitchLanguage, intl, setMenuVisible,
}) => {
  const classes = classNames({
    'nw-menu--open': visible,
  });

  const nav = useRef(null);

  const menuItems = [
    {
      url: PATH.HOME,
      content: (
        <Fragment>
          <FontAwesomeIcon icon="home" />
          <FormattedMessage id="sidemenu.my_investment" />
        </Fragment>),
    },
    { url: PATH.TRANSACTION_HISTORY, content: <FormattedMessage id="sidemenu.transactions_history" /> },
    { url: PATH.RISK_PROFILING, content: <FormattedMessage id="sidemenu.risk_profiling" /> },
    { url: PATH.CATALOGUE, content: <FormattedMessage id="sidemenu.star_mutual_funds" /> },
    { url: PATH.FAQ, content: <FormattedMessage id="sidemenu.faq" /> },
    {
      url: '#',
      content: (
        <div className="nw-menu__item--flex">
          <FormattedMessage id="sidemenu.language" />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div className="nw-menu__language" onClick={() => onSwitchLanguage()}>
            <span className={currentLocale === ENGLISH ? 'nw-menu__language--active' : ''}>EN</span>
            <span className={currentLocale === BAHASA ? 'nw-menu__language--active' : ''}>ID</span>
          </div>
        </div>),
      noLink: true,
    },
    { url: PATH.LOGIN, content: <FormattedMessage id="sidemenu.logout" /> },
  ];

  const hideNavOnClickOutside = (e) => {
    if (!nav.current.contains(e.target) && visible) {
      setVisible(false);
    }
  };

  useEffect(() => {
    const App = document.querySelector('.App');
    App.addEventListener('click', hideNavOnClickOutside);
    return () => {
      App.removeEventListener('click', hideNavOnClickOutside);
    };
  });

  const handleClick = (closeMenu) => {
    if (closeMenu) setMenuVisible(false);
  };

  return (
    <div className={`nw-menu ${classes}`}>
      <div className="nw-menu__container" ref={nav}>
        <div className="nw-menu__top">
          <h2 className="nw-menu__name">Fadhlan Hasan</h2>
          <div className="nw-menu__date">{ intl.formatMessage({ id: 'sidemenu.last_login' }, { date: '12 July 2019', time: '12:54 pm' })}</div>
        </div>
        <div className="nw-menu__bottom">
          <ul>
            { menuItems && menuItems.map((item, idx) => (
              <li key={`${item.url} ${idx}`} className={item.url === location.pathname ? 'nw-menu__item--current' : ''} onClick={() => handleClick(!item.noLink)}>
                {
                  !item.noLink
                    ? <Link to={item.url}>{item.content}</Link>
                    : <Fragment>{item.content}</Fragment>
                }
              </li>
            ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

Menu.propTypes = {
  /** menu visible */
  visible: PropTypes.bool,
  /** menu set visible */
  setVisible: PropTypes.func,
};

Menu.defaultProps = {
  visible: false,
  setVisible: null,
};

const mapStateToProps = state => ({
  currentLocale: state.language.locale,
});

const mapDispatchToProps = dispatch => ({
  onSwitchLanguage: () => dispatch(switchLanguage()),
  setMenuVisible: e => dispatch(setOpenMenu(e)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu)));
