import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Input } from 'components';
import { PATH } from 'shared/constant';

class Login extends Component {
  render() {
    const { intl } = this.props;

    return (
      <div className="login-tab">
        <h2><FormattedMessage id="login.loginToYourAccount" /></h2>
        {/* eslint-disable-next-line jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control */}
        <label>
          <FormattedMessage id="login.name" />
          <Input isLinedInput name="username" required />
        </label>
        {/* eslint-disable-next-line jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control */}
        <label>
          <FormattedMessage id="login.password" />
          <div className="login-tab__password">
            <Input isLinedInput name="password" type="password" required />
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#"><FormattedMessage id="login.forgot" /></a>
          </div>
        </label>
        <Button value={intl.formatMessage({ id: 'login.login' })} onButtonClick={() => this.props.history.push(PATH.HOME)} />
      </div>
    );
  }
}

export default injectIntl(Login);
