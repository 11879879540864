import { ENGLISH, BAHASA } from 'shared/constant';
import enMessages from 'shared/translations/en.json';
import idMessages from 'shared/translations/id.json';
import {
  ACTION_TYPE,
} from './actionTypes';

const switchLanguage = () => (dispatch, getState) => {
  const currentLanguage = getState().language.locale;
  const englishState = { language: { locale: ENGLISH, messages: enMessages } };
  const bahasaState = { language: { locale: BAHASA, messages: idMessages } };
  const payload = currentLanguage === ENGLISH ? bahasaState : englishState;
  dispatch({
    type: ACTION_TYPE.SWITCH_LANGUAGE,
    payload,
  });
};

export default switchLanguage;
