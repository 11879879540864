import React from 'react';
import PropTypes from 'prop-types';
import './CardHeader.scss';

const CardHeader = ({
  icon, title, subTitle, desc, button, onClick,
}) => (
  <div className="nw-card__header-withIcon">
    { icon && <div className="nw-card__header-withIcon-icon">{icon}</div>}
    <div className="nw-card__header-text">
      <div>
        { title && <div className="nw-card__header-withIcon-title">{title}</div>}
        { subTitle && <div className="nw-card__header-withIcon-subTitle">{subTitle}</div>}
        { desc && <div className="nw-card__header-withIcon-desc">{desc}</div>}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      {button && <div onClick={onClick} className="nw-card__header-withIcon-button">{ button }</div>}
    </div>
  </div>
);

CardHeader.propTypes = {
  /** card header icon */
  icon: PropTypes.node,
  /** card header title */
  title: PropTypes.string,
  /** card header subTitle */
  subTitle: PropTypes.string,
  /** card header desc */
  desc: PropTypes.node,
  /** card header right button */
  button: PropTypes.node,
  /** card header button click */
  onClick: PropTypes.func,
};

CardHeader.defaultProps = {
  icon: null,
  title: '',
  subTitle: '',
  desc: null,
  button: null,
  onClick: null,
};

export default CardHeader;
