import React, { Children, useState } from 'react';
import PropTypes from 'prop-types';
import './TabsMenu.scss';

const TabsMenu = (props) => {
  const {
    menu, children, className, defaultValue,
  } = props;
  const [currentNav, setCurrentNav] = useState(defaultValue);

  const selectNav = (e, item) => {
    setCurrentNav(item);
    const currentActive = e.currentTarget;
    const nav = currentActive.parentElement;
    const offset = (el) => {
      const rect = el.getBoundingClientRect();
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    };
    const offsetLeft = offset(currentActive).left;
    const offsetRight = element => element && (window.innerWidth - (offset(element).left + element.offsetWidth));

    if (currentActive.previousSibling !== null && (offsetLeft < 0 || offset(currentActive.previousSibling).left < 0)) {
      nav.style.transform = `translateX(${(window.innerWidth - currentActive.offsetWidth) / 2 + currentActive.offsetWidth - offsetRight(currentActive)}px)`;
    } else if (!currentActive.previousSibling) {
      nav.style.transform = 'translateX(0)';
    }
    if (offsetRight(currentActive) < 0 || (currentActive.nextSibling && offsetRight(currentActive.nextSibling) < 0)) {
      nav.style.transform = `translateX(-${(window.innerWidth - currentActive.offsetWidth) / 2 - offsetRight(currentActive)}px)`;
    }
  };

  return (
    <div className={`tabs-menu ${className || ''}`}>
      <ul className="tabs-menu__tabs">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
        {menu && menu.map((item, idx) => <li key={`nav-${idx}`} onClick={e => selectNav(e, idx)} className={`${currentNav === idx ? 'tabs-menu--active' : ''}`}>{item}</li>)}
      </ul>
      <div className="tabs-menu__content">
        {Children.map(children, (child, i) => <div className={`${currentNav === i ? 'tabs-menu__content--active' : ''}`}>{child}</div>)}
      </div>
    </div>

  );
};

TabsMenu.propTypes = {
  /** List tabs name */
  menu: PropTypes.arrayOf(PropTypes.string),
  /** List tabs content */
  children: PropTypes.node,
  /** Menu class */
  className: PropTypes.string,
  /** Default value */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TabsMenu.defaultProps = {
  menu: [],
  children: null,
  className: '',
  defaultValue: 0,
};

export default TabsMenu;
