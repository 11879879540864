import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Navbar, LayoutWithNavbar, Button, Agreement, ItemList,
} from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PATH } from 'shared/constant';

import { submissionTime } from 'shared/redux/actions';

class PaymentConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAgree: false,
    };
  }

  componentDidMount() {
    const { history, listOfPayment } = this.props;
    if (listOfPayment.length === 0) {
      history.push(PATH.BUY_FUND);
    }
  }

    onBackButton = () => {
      this.props.history.goBack();
    }

    handleConfirmation = () => {
      const { isAgree } = this.state;
      const { onSubmissionTime, history } = this.props;
      if (isAgree) {
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        onSubmissionTime(tomorrow);
        history.push(PATH.PAYMENT_COMPLETE);
      }
    }

    render() {
      const { intl, listOfPayment } = this.props;
      const { isAgree } = this.state;
      return (
        <LayoutWithNavbar
          header={(
            <Navbar
              title={intl.formatMessage({ id: 'paymentConfirm.confirmation' })}
              leftButton={<FontAwesomeIcon icon="chevron-left" />}
              onClickLeft={this.onBackButton}
            />
                )}
          className="nw-page-payment"
        >
          <ItemList showHeader={false} listObj={listOfPayment} />
          <Agreement
            agreement={(
              <>
                <strong>{intl.formatMessage({ id: 'paymentConfirm.agreementTitle' })}</strong>
                {` ${intl.formatMessage({ id: 'paymentConfirm.agreementContent' })}`}
              </>
                      )}
            onCheckAgree={(isChecked) => {
              this.setState({ isAgree: isChecked });
            }}
          />
          <Button
            isSecondary
            value={intl.formatMessage({ id: 'paymentConfirm.continue' })}
            onButtonClick={this.handleConfirmation}
            isDisabled={!isAgree}
          />
        </LayoutWithNavbar>
      );
    }
}

PaymentConfirmation.propTypes = {
  listOfPayment: PropTypes.arrayOf(PropTypes.object),
};

PaymentConfirmation.defaultProps = {
  listOfPayment: [],
};

const mapStateToProps = state => ({
  listOfPayment: state.payment.listOfPayment,
});

const mapDispatchToProps = ({
  onSubmissionTime: submissionTime,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentConfirmation));
