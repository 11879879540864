import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './LayoutWithNavbar.scss';

/**
 * LayoutWithNavbar
 * @param { header, children } props
 * specifically used for page with navbar layout
 * the navbar is fixed on top and the only scrollable area is the content part or body
 */

const LayoutWithNavbar = ({
  header, children, className, isNavBgTransparent,
}) => {
  const classes = classNames({
    'nw-page': true,
    'nw-page--transparent-header': isNavBgTransparent,

  });
  return (
    <div className={`${classes} ${className}`}>
      <header className="nw-page__header">{header}</header>
      <div className="nw-page__body" id="nw-page__body">
        {children}
      </div>
    </div>
  );
};

LayoutWithNavbar.propTypes = {
  /** Layout header specifically for navbar */
  header: PropTypes.node,
  /** page body */
  children: PropTypes.node,
  /** page class */
  className: PropTypes.string,
  /** is background transparent */
  isNavBgTransparent: PropTypes.bool,
};

LayoutWithNavbar.defaultProps = {
  header: null,
  children: null,
  className: '',
  isNavBgTransparent: false,
};

export default LayoutWithNavbar;
