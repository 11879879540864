import React from 'react';
import PropTypes from 'prop-types';
import './TransactionItem.scss';
import IconBuy from 'assets/icons/iconBuy.png';
import IconSell from 'assets/icons/iconSell.png';

import { Link } from 'react-router-dom';
import { PATH } from 'shared/constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { injectIntl } from 'react-intl';

const TransactionItem = ({
  title, time, numbers, status, type, intl,
}) => (
  <div className="nw-transaction-item">
    <div>
      <h4>{title}</h4>
      {status ? (
        <span className={`nw-transaction-item__status nw-transaction-item__status--${status}`}>
          {intl.formatMessage({ id: `transaction.${status}` })}
        </span>
      ) : <></>}
    </div>
    <div>
      <div className="nw-transaction-item__times">
        <img src={type === 'buy' ? IconBuy : IconSell} alt="" />
        <div className="nw-transaction-item__times__grey">{time}</div>
      </div>
      <div className="nw-transaction-item__number">{`${type === 'sell' ? '-' : ''} ${numbers}`}</div>
    </div>
    {status === 'pending' && (
    <nav>
      <Link to={PATH.PAYMENT_COMPLETE}>{intl.formatMessage({ id: 'transaction.markPayment' })}</Link>
      <FontAwesomeIcon icon="angle-right" />
    </nav>
    )}
  </div>
);

TransactionItem.propTypes = {
  /** top left */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** buy / sell icon */
  status: PropTypes.string,
  /** bottom left */
  time: PropTypes.string,
  /** top right */
  numbers: PropTypes.string,
};

TransactionItem.defaultProps = {
  title: '120 unit',
  status: '',
  time: '12/03/2019',
  numbers: '',
};

export default injectIntl(TransactionItem);
