import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  Card, Navbar, LayoutWithNavbar, SubTitle, Button,
} from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PATH } from 'shared/constant';


class Payment extends Component {
  constructor() {
    super();
    this.state = {
      paymentMethod: null,
    };
  }

  onBackButton = () => {
    this.props.history.goBack();
  }

  onContinueButton = () => {
    const { paymentMethod } = this.state;
    console.log(paymentMethod);
    if (paymentMethod === 'bank-transfer') return this.props.history.push(PATH.PAYMENT_CONFIRM);
    return this.props.history.push(PATH.PAYMENT_CARD);
  }

  render() {
    const { intl } = this.props;
    const { paymentMethod } = this.state;
    return (
      <LayoutWithNavbar
        header={(
          <Navbar
            title={intl.formatMessage({ id: 'payment.paymentMethod' })}
            leftButton={<FontAwesomeIcon icon="chevron-left" />}
            onClickLeft={this.onBackButton}
          />
                )}
        className="nw-page-payment"
      >
        <Card
          header={(
            <SubTitle label={intl.formatMessage({ id: 'payment.howWouldYoulike' })} />
                )}
        >
          <Button
            value={intl.formatMessage({ id: 'payment.bankTransfer' })}
            onButtonClick={() => { this.setState({ paymentMethod: 'bank-transfer' }); }}
            customClassName={paymentMethod === 'bank-transfer' ? 'nw-button--selected' : ''}
            isOutline
            isFullWidth
          />
          <Button
            value={intl.formatMessage({ id: 'payment.debitCard' })}
            onButtonClick={() => { this.setState({ paymentMethod: 'debit-card' }); }}
            customClassName={paymentMethod === 'debit-card' ? 'nw-button--selected' : ''}
            isOutline
            isFullWidth
          />
        </Card>
        <Button
          isSecondary
          isDisabled={!this.state.paymentMethod}
          value={intl.formatMessage({ id: 'payment.continue' })}
          onButtonClick={this.onContinueButton}
        />
      </LayoutWithNavbar>
    );
  }
}

export default injectIntl(Payment);
