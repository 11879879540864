import React, { Component, Fragment } from 'react';
import {
  Question, LayoutWithNavbar, Navbar, Button,
  Spin,
} from 'components';
import { injectIntl } from 'react-intl';
import { PATH, STORAGE } from 'shared/constant';
import moment from 'moment';
import { connect } from 'react-redux';
import { riskProfile } from 'shared/redux/actions';
import _ from 'lodash';
import utilitiesService from 'shared/utils/utilitiesService';
import { animateScroll as scroll } from 'react-scroll';
import './RiskProfiling.scss';

class RiskQuestions extends Component {
  constructor() {
    super();
    this.state = {
      answers: [],
      submitted: false,
    };
    this.handleAnswer = this.handleAnswer.bind(this);
    this.handleDone = this.handleDone.bind(this);
  }

  componentDidMount() {
    const { getRiskQuestions } = this.props;
    getRiskQuestions();
  }

  componentDidUpdate(prevProps) {
    const {
      submitAnswerSuccess,
      submitAnswerFailure,
      submitAnswerError,
    } = this.props;

    if (submitAnswerSuccess && submitAnswerSuccess !== prevProps.submitAnswerSuccess) {
      localStorage.setItem(STORAGE.RISK_EXPIRE, moment('2019-10-16').valueOf());
      this.props.history.push(PATH.RISK_PROFILING);
    } else if (submitAnswerFailure && submitAnswerFailure !== prevProps.submitAnswerFailure) {
      utilitiesService.displayErrorMessage(submitAnswerError);
    }
  }

  onBackButton = () => {
    this.props.history.goBack();
  };

  scrollBodyTo = (index) => {
    const { questions } = this.props;
    if (index < questions.length) {
      scroll.scrollTo(document.querySelectorAll('.question-box')[index].offsetTop, {
        duration: 800,
        containerId: 'nw-page__body',
        delay: 0,
        smooth: 'linear',
      });
    }
  };

  handleDone = () => {
    const { submitRiskAnswers, questions } = this.props;

    // check if all questions are answered
    if (_.isEmpty(this.state.answers) || questions.length > this.state.answers.length || this.state.answers.includes(undefined)) {
      const errorMessage = 'Please answer all questions';
      utilitiesService.displayErrorMessage(errorMessage);
      this.setState({
        submitted: true,
      });
      // scroll to unanswered question
      if (!_.isEmpty(this.state.answers)) {
        let unansweredIndex = this.state.answers.findIndex(item => _.isEmpty(item));
        if (unansweredIndex === -1) unansweredIndex = this.state.answers.length;
        this.scrollBodyTo(unansweredIndex);
      } else {
        this.scrollBodyTo(0);
      }
    } else {
      submitRiskAnswers(this.state.answers);
    }
  };

  handleAnswer = (answer, idx) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const answers = [...this.state.answers];
    if (idx > -1) answers[idx] = answer;
    this.setState({
      answers,
    });

    // scroll to next question
    this.scrollBodyTo(idx + 1);
  };

  render() {
    const { intl, questions, getRiskQuestionPending } = this.props;
    return (
      <LayoutWithNavbar
        header={(
          <Navbar
            title={intl.formatMessage({ id: 'riskProfile' })}
            withSideMenu
          />
            )}
        className="nw-page-questions"
      >
        <Spin spinning={getRiskQuestionPending}>
          {questions
            && (
            <Fragment>
              {questions.map((item, idx) => (
                <Question
                  category={item.category}
                  question={intl.formatMessage({ id: item['question-short-code'] })}
                  answers={item.answers}
                  key={item['question-short-code']}
                  index={idx}
                  onSelect={this.handleAnswer}
                  isAnwered={!this.state.submitted || !_.isEmpty(this.state.answers[idx])}
                />
              ))}
              {/* eslint-disable-next-line react/jsx-no-bind */}
              <Button value="Done" onButtonClick={this.handleDone.bind(this)} />
            </Fragment>
            )
            }
        </Spin>
      </LayoutWithNavbar>
    );
  }
}

const mapStateToProps = state => ({
  questions: state.riskProfile.questions,
  score: state.riskProfile.score,
  profile: state.riskProfile.profile,
  getRiskQuestionPending: state.riskProfile.getRiskQuestionPending,
  submitAnswerSuccess: state.riskProfile.submitAnswerSuccess,
  submitAnswerFailure: state.riskProfile.submitAnswerFailure,
  submitAnswerError: state.riskProfile.submitAnswerError,
});

const mapDispatchToProps = dispatch => ({
  getRiskQuestions: () => {
    dispatch(riskProfile.getRiskQuestions());
  },
  submitRiskAnswers: (answers) => {
    dispatch(riskProfile.submitRiskAnswers(answers));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RiskQuestions));
