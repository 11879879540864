import { ACTION_TYPE } from '../actions/actionTypes';

const payment = (state = [], action) => {
  switch (action.type) {
    case ACTION_TYPE.PAYMENT_SUMMARY:
      return { ...state, listOfPayment: action.payload };
    case ACTION_TYPE.SUBMISSION_TIME:
      return { ...state, submissionTime: action.payload };
    default:
      return state;
  }
};

export default payment;
