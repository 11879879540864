/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './MutualFund.scss';
import baseUrl from 'shared/utils/baseUrl';

import RiskHigh from 'assets/icons/iconRiskHigh.png';
import RiskMedium from 'assets/icons/iconRiskMedium.png';
import RiskLow from 'assets/icons/iconRiskLow.png';
// import CheckBulletYellow from 'assets/icons/checkBulletYellow.png';
import CheckBulletGreen from 'assets/icons/checkBulletGreen.png';
import Closed from 'assets/icons/closed@2x.png';
// import ArrowRight from 'assets/icons/chevron-thin-right.svg';
import ArrowRight from 'assets/icons/rightArrow@2x.png';
import { PATH } from 'shared/constant';
import Button from '../Button/Button';


const HighRiskStatus = 'High Risk';
const MediumRiskStatus = 'Medium Risk';

class MutualFund extends Component {
  state = {
    riskIcon: '',
  }

  componentDidMount() {
    const { status } = this.props;
    if (status === HighRiskStatus) {
      this.setState({ riskIcon: RiskHigh });
    } else if (status === MediumRiskStatus) {
      this.setState({ riskIcon: RiskMedium });
    } else {
      this.setState({ riskIcon: RiskLow });
    }
  }

  render() {
    const {
      isInvest, title, category, description, listOfvalue, banner, status, id, showInvest, buttonValue,
      onButtonClick,
    } = this.props;
    return (
      <div
        className="nw-mutual-fund"
        onClick={() => {
          this.props.history.push(`${PATH.FUND_FACTSHEET}/${id}`);
        }}
      >
        <header
          className="nw-mutual-fund__header"
          style={{
            backgroundImage: `url(${baseUrl}/${banner})`,
          }}
        >
          <div className="nw-mutual-fund__header__group">
            <div className="nw-mutual-fund__header__group__status">
              <span>{status}</span>
              <img src={this.state.riskIcon} alt="status" />
            </div>
            {showInvest
            && (
            <div className="nw-mutual-fund__header__group__status-btn">
              {isInvest
                ? (
                  <Button
                    isSmall
                    isSecondary
                    value={buttonValue}
                    onButtonClick={(e) => {
                      e.stopPropagation();
                      onButtonClick();
                    }}
                  />
                )
                : (
                  <div className="nw-mutual-fund__header__group__status-btn__closed">
                    <img src={Closed} alt="Closed" />
                    <p>Closed for subscription</p>
                  </div>
                )
                  }
            </div>
            )
            }
          </div>
        </header>
        <section>
          <div className="nw-mutual-fund__navigate">
            <a href={category.href}>{category.label}</a>
            <img src={ArrowRight} alt=">" />
          </div>
          <h3>{title}</h3>
          {description ? <h4>{description}</h4> : <></>}
          <ul>
            {listOfvalue.map((val, key) => (
              <li key={key}>
                <p>{val.value}</p>
                <div className="nw-mutual-fund__analytics">
                  <img src={CheckBulletGreen} alt="^" />
                  <h4>{val.descr}</h4>
                </div>
              </li>
            ))}
          </ul>
        </section>
      </div>
    );
  }
}

MutualFund.propTypes = {
  // display invest button or not
  isInvest: PropTypes.bool,
  // status label
  status: PropTypes.string,
  // mutual fund title
  title: PropTypes.string,
  // mutual fund category
  category: PropTypes.objectOf(PropTypes.string),
  // mutual fund description
  description: PropTypes.string,
  // list of mutual fund value
  listOfvalue: PropTypes.arrayOf(PropTypes.object),
  // id of item
  id: PropTypes.string,
  // show invest
  showInvest: PropTypes.bool,
  /** Button Value */
  buttonValue: PropTypes.string,
};

MutualFund.defaultProps = {
  id: 'STAR_Capital_Protected_Fund_V',
  isInvest: true,
  status: 'High Risk',
  title: 'NW Capital Protected Fund V',
  category: {
    label: 'Money Market',
    href: '#',
  },
  description: '',
  listOfvalue: [{
    value: '+ 11.68%',
    descr: '3y results',
  }, {
    value: 'Rp. 500 k',
    descr: 'Minimum',
  }, {
    value: '5,6000',
    descr: 'NAV',
  }],
  showInvest: true,
  buttonValue: 'Invest Now',
};

export default MutualFund;
