// eslint-disable-next-line import/prefer-default-export
export const ACTION_TYPE = {
  SWITCH_LANGUAGE: 'SWITCH_LANGUAGE',
  TOGGLE_MENU: 'TOGGLE_MENU',
  HOME_TABS: 'HOME_TABS',
  SELECT_FUND_FACTSHEET: 'SELECT_FUND_FACTSHEET',
  RISK_PROFILE_QUESTION_PENDING: 'RISK_PROFILE_QUESTION_PENDING',
  RISK_PROFILE_QUESTION_SUCCESS: 'RISK_PROFILE_QUESTION_SUCCESS',
  RISK_PROFILE_QUESTION_FAILURE: 'RISK_PROFILE_QUESTION_FAILURE',
  RISK_PROFILE_SUBMIT_ANSWER_PENDING: 'RISK_PROFILE_SUBMIT_ANSWER_PENDING',
  RISK_PROFILE_SUBMIT_ANSWER_SUCCESS: 'RISK_PROFILE_SUBMIT_ANSWER_SUCCESS',
  RISK_PROFILE_SUBMIT_ANSWER_FAILURE: 'RISK_PROFILE_SUBMIT_ANSWER_FAILURE',
  PAYMENT_SUMMARY: 'PAYMENT_SUMMARY',
  SUBMISSION_TIME: 'SUBMISSION_TIME',
  TRANSACTION_FILTER: 'TRANSACTION_FILTER',
};
