import React from 'react';
import PropTypes from 'prop-types';
import './IconButton.scss';

const IconButton = ({
  value, icon, activeIcon, onButtonClick, isActive,
}) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <div className={`nw-icon-button ${isActive ? 'nw-icon-button--active' : ''}`} onClick={onButtonClick}>
    {icon && <div className="nw-icon-button__icon">{icon}</div>}
    {activeIcon && <div className="nw-icon-button__icon nw-icon-button__icon--active">{activeIcon}</div>}
    <span>{value}</span>
  </div>
);

IconButton.propTypes = {
  /** button text */
  value: PropTypes.string,
  /** button regular icon */
  icon: PropTypes.node,
  /** button active icon */
  activeIcon: PropTypes.node,
  /** on button click */
  onButtonClick: PropTypes.func,
  /** if button active */
  isActive: PropTypes.bool,
};

IconButton.defaultProps = {
  value: '',
  icon: null,
  activeIcon: null,
  onButtonClick: () => {},
  isActive: false,
};

export default IconButton;
