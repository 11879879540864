import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Input.scss';

const Input = ({
  type, name, placeholder, disabled, required, isLinedInput, onChange, customClass, value,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const inputClass = classNames({
    'nw-input--lined': isLinedInput,
  });

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <input
      className={`nw-input ${inputClass} ${customClass}`}
      type={type}
      name={name}
      value={inputValue}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      onChange={onChange}
      spellCheck={false}
    />
  );
};

Input.propTypes = {
  /** input type */
  type: PropTypes.string,
  /** input name */
  name: PropTypes.string,
  /** input placeholder */
  placeholder: PropTypes.string,
  /** input is disabled */
  disabled: PropTypes.bool,
  /** input is required */
  required: PropTypes.bool,
  /** alternate lined style */
  isLinedInput: PropTypes.bool,
  /** on input change */
  onChange: PropTypes.func,
  /** Addition class name */
  customClass: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
  name: '',
  placeholder: '',
  disabled: false,
  required: false,
  isLinedInput: false,
  onChange: () => {},
  customClass: '',
};

export default Input;
