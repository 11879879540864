import React from 'react';
import PropTypes from 'prop-types';
import Marker from 'assets/icons/pin@2x.png';
import './RiskScale.scss';

const RiskScale = ({ percent }) => (
  <div className="risk-scale">
    <div className="risk-scale__marker" style={{ left: `${percent}%` }}>
      <img src={Marker} alt="marker" />
      <span>{percent}</span>
    </div>
  </div>
);

RiskScale.propTypes = {
  /** percent number */
  percent: PropTypes.number,
};

RiskScale.defaultProps = {
  percent: 0.0,
};

export default RiskScale;
