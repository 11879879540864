import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Swipeable } from 'react-swipeable';

import { PATH } from 'shared/constant';

import {
  MutualFund, Navbar, LayoutWithNavbar,
} from 'components';
import CatalogueFilter from './CatalogueFilter';

class CataloguePage extends Component {
  constructor() {
    super();
    this.state = {
      showFilter: false,
      funds: [],
      filter: {
        category: [],
        type: [],
      },
    };
  }

  componentDidMount() {
    const { catalogue } = this.props;
    this.setState({
      funds: catalogue,
    });
  }

  onBackButton = () => {
    this.props.history.goBack();
  };

  setFilter = (obj) => {
    const { filter } = this.state;
    this.setState({
      filter: {
        category: obj.category || filter.category,
        type: obj.type || filter.type,
      },
    });
  };

  handleFilter = () => {
    const { catalogue, intl } = this.props;
    const { filter } = this.state;

    const subscriptionLabel = subscription => (
      subscription ? 'catalogue.openForSubscription' : 'catalogue.closedForSubscription'
    );

    const newFunds = catalogue.filter(item => (
      filter.category.map(i => intl.formatMessage({ id: i })).includes(item.category.label) && filter.type.includes(subscriptionLabel(item.subscription))
    ));

    this.setState({
      showFilter: false,
      funds: newFunds,
    });
  };

  render() {
    const { intl } = this.props;
    const { funds } = this.state;

    return (
      <Swipeable onSwipedRight={() => this.setState({ showFilter: false })} onSwipedLeft={() => this.setState({ showFilter: true })}>
        <LayoutWithNavbar
          header={(
            <Navbar
              title={intl.formatMessage({ id: 'catalogue.title' })}
              rightButton={intl.formatMessage({ id: 'catalogue.filter' })}
              onClickRight={() => this.setState({ showFilter: true })}
              withSideMenu
            />
          )}
        >
          <div className="nw-catalogue-page">
            {funds.map((value, key) => (
              <MutualFund
                key={key}
                history={this.props.history}
                id={value.id}
                isInvest={value.isInvest}
                status={value.status}
                title={value.title}
                category={value.category}
                description={value.description}
                listOfvalue={value.listOfValue}
                banner={value.banner}
                onButtonClick={() => this.props.history.push(`${PATH.BUY_FUND}`)}
              />
            ))}
          </div>
          <CatalogueFilter
            visible={this.state.showFilter}
            setFilter={this.setFilter}
            onOk={this.handleFilter}
          />
        </LayoutWithNavbar>
      </Swipeable>
    );
  }
}

CataloguePage.propTypes = {
  // The list of mutual fund
  catalogue: PropTypes.arrayOf(PropTypes.object),
};

CataloguePage.defaultProps = {
  catalogue: [{
    id: '1',
    status: 'High Risk',
    banner: 'banner-equity.png',
    isInvest: true,
    category: {
      label: 'Equity',
      href: '#',
    },
    title: 'NW SICAV EM Frontier Equity Fund',
    description: 'Benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 171 k',
      descr: 'Minimum',
    }, {
      value: '5,6000',
      descr: 'NAV',
    }],
    subscription: true,
  }, {
    id: '2',
    status: 'Medium Risk',
    banner: 'banner-equity2.png',
    isInvest: true,
    category: {
      label: 'Equity',
      href: '#',
    },
    title: 'NW SICAV EM Active Equity Fund',
    description: 'Benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 133 k',
      descr: 'Minimum',
    }, {
      value: '5,6000',
      descr: 'NAV',
    }],
    subscription: true,
  }, {
    id: '3',
    status: 'High Risk',
    banner: 'graph@2x.png',
    isInvest: true,
    category: {
      label: 'Fixed Income',
      href: '#',
    },
    title: 'NW Fixed Income Dynamic',
    description: 'Benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 500 k',
      descr: 'Minimum',
    }, {
      value: '5,6000',
      descr: 'NAV',
    }],
    subscription: false,
  }, {
    id: '4',
    status: 'Low Risk',
    banner: 'eggs@2x.png',
    isInvest: true,
    category: {
      label: 'Fixed Income',
      href: '#',
    },
    title: 'NW Fixed Income III',
    description: 'Double benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 500 k',
      descr: 'Minimum',
    }, {
      value: '18,6001',
      descr: 'NAV',
    }],
    subscription: true,
  }, {
    id: '5',
    status: 'Low Risk',
    banner: 'banner-balanced.png',
    isInvest: true,
    category: {
      label: 'Balanced',
      href: '#',
    },
    title: 'NW Balanced Syariah Fund',
    description: 'Double benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 500 k',
      descr: 'Minimum',
    }, {
      value: '18,6001',
      descr: 'NAV',
    }],
    subscription: false,
  }, {
    id: '6',
    status: 'Medium Risk',
    banner: 'money@2x.png',
    isInvest: true,
    category: {
      label: 'Money Market',
      href: '#',
    },
    title: 'Capital Protected Fund V',
    description: 'Double benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 500 k',
      descr: 'Minimum',
    }, {
      value: '18,6001',
      descr: 'NAV',
    }],
    subscription: false,
  }, {
    id: '7',
    status: 'Low Risk',
    banner: 'hands@2x.png',
    isInvest: true,
    category: {
      label: 'Fixed Income',
      href: '#',
    },
    title: 'Fixed Income II',
    description: 'Benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 500 k',
      descr: 'Minimum',
    }, {
      value: '18,6119',
      descr: 'NAV',
    }],
    subscription: true,
  }, {
    id: '8',
    status: 'Low Risk',
    banner: 'banner-money-market.png',
    isInvest: false,
    category: {
      label: 'Equity',
      href: '#',
    },
    title: 'NW SICAV EM Indonesian Equity Fund',
    description: 'Double benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 500 k',
      descr: 'Minimum',
    }, {
      value: '18,6001',
      descr: 'NAV',
    }],
    subscription: false,
  }, {
    id: '9',
    status: 'Medium Risk',
    banner: 'banner-fixed-income.png',
    isInvest: false,
    category: {
      label: 'Fixed Income',
      href: '#',
    },
    title: 'NW Fixed Income IV',
    description: 'Double benefits of lower risk, big returns.',
    listOfValue: [{
      value: '+ 11.68%',
      descr: '3y results',
    }, {
      value: 'Rp. 500 k',
      descr: 'Minimum',
    }, {
      value: '18,6001',
      descr: 'NAV',
    }],
    subscription: false,
  },
  ],
};

export default injectIntl(CataloguePage);
