import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import './OptionBox.scss';

const OptionBox = ({
  type, position, name, description, value, onSelect, defaultChecked, className,
}) => {
  const optionClass = classNames({
    'nw-option-box--left': position === 'left',
  });

  const handleCheckbox = () => {
    onSelect(value);
  };

  return (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for
    <label className={`nw-option-box ${optionClass} ${className}`}>
      {
            type === 'checkbox'
              ? (
                <input
                  type="checkbox"
                  onClick={handleCheckbox}
                  value={value}
                  defaultChecked={defaultChecked}
                />
              )
              : <input type="radio" name={name} onChange={handleCheckbox} value={value} defaultChecked={defaultChecked} />
        }
      <span className="nw-option-box__text">{description}</span>
      {defaultChecked
        ? <span className="nw-option-box__checkmark--checked"><FontAwesomeIcon icon="check-circle" /></span>
        : <span className="nw-option-box__checkmark"><FontAwesomeIcon icon="check-circle" /></span>
          }
    </label>
  );
};

OptionBox.propTypes = {
  /** box type */
  type: PropTypes.string,
  /** checkmark position */
  position: PropTypes.string,
  /** radio button name */
  name: PropTypes.string,
  /** input text description */
  description: PropTypes.node,
  /** input value */
  value: PropTypes.string,
  /** onSelect event */
  onSelect: PropTypes.func,
  /** if checked */
  defaultChecked: PropTypes.bool,
  // option box class
  className: PropTypes.string,
};

OptionBox.defaultProps = {
  type: 'checkbox',
  position: 'right',
  name: '',
  description: null,
  value: '',
  onSelect: () => {},
  defaultChecked: false,
  className: '',
};

export default OptionBox;
