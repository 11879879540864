import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Navbar, TabsMenu, Menu, ActionModal, Button,
} from 'components';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Swipeable } from 'react-swipeable';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATH } from 'shared/constant';
import IconMore from 'assets/icons/stuffWhiteDots@2x.png';
import { setOpenMenu, setTab } from 'shared/redux/actions';

import MyPortfolio from './Tabs/MyPortfolio';
import MyGoals from './Tabs/MyGoals';
import HomeHeader from './HomeHeader';
import './Homepage.scss';

/**
 * @class Homepage
 * app's main page
 */
class Homepage extends Component {
  constructor() {
    super();
    this.state = {
      showAction: false,
    };
    this.handleCancelAction = this.handleCancelAction.bind(this);
  }

  componentDidMount() {
    // hide menu on route change
    this.onRouteChange = this.props.history.listen(() => {
      this.props.setMenuVisible(false);
    });
  }

  componentWillUnmount() {
    this.onRouteChange();
    this.props.selectMyPortfolio();
  }

  handleCancelAction() {
    this.setState({
      showAction: false,
    });
  }

  render() {
    const { intl, openMenu, setMenuVisible } = this.props;
    return (
      <Fragment>
        <Swipeable onSwipedRight={() => setMenuVisible(true)} onSwipedLeft={() => setMenuVisible(false)}>

          <HomeHeader
            navbar={(
              <Navbar
                title={intl.formatMessage({ id: 'home.hi' }, { name: 'Fadhlan Hasan' })}
                leftButton={<FontAwesomeIcon icon="bars" />}
                // rightButton={<FontAwesomeIcon icon="ellipsis-h" />}
                rightButton={<img src={IconMore} alt={intl.formatMessage({ id: 'transaction.transactionHistory' })} />}
                onClickLeft={() => setMenuVisible(true)}
                onClickRight={() => this.setState({ showAction: true })}
              />
            )}
            title={intl.formatMessage({ id: 'home.totalInvestmentValue' })}
            value="Rp. 100,165,761,472"
            percentageChange={(
              <Fragment>
                <FontAwesomeIcon icon="arrow-up" />
                <span>4.06%</span>
              </Fragment>
                )}
            valueChange={intl.formatMessage({ id: 'home.upByValue' }, { value: 'Rp. 678,132' })}
          />

          <section className="nw-homepage-body">
            {/* TABS */}
            <TabsMenu defaultValue={this.props.currentTab} menu={[intl.formatMessage({ id: 'home.myPortfolio' }), intl.formatMessage({ id: 'home.myGoals' })]} className="nw-home-tabs">
              <MyPortfolio
                {...this.props}
                button={(
                  <Button isOutline isFullWidth value={intl.formatMessage({ id: 'portfolio.portfolioDetails' })} onButtonClick={() => this.props.history.push(PATH.PORTFOLIO_DETAIL)} />
                  )}
              />
              <MyGoals history={this.props.history} />
            </TabsMenu>
          </section>
          <Menu visible={openMenu} setVisible={setMenuVisible} />
        </Swipeable>
        <ActionModal title={intl.formatMessage({ id: 'portfolio.portfolioDetails' })} visible={this.state.showAction} onCancel={this.handleCancelAction}>
          <Link to={PATH.TRANSACTION_HISTORY}><FormattedMessage id="transaction.transactionHistory" /></Link>
        </ActionModal>
      </Fragment>

    );
  }
}

const mapStateToProps = state => ({
  openMenu: state.toggleMenu.openMenu,
  currentTab: state.homeTabs.currentNav,
});

const mapDispatchToProps = dispatch => ({
  setMenuVisible: e => dispatch(setOpenMenu(e)),
  selectMyPortfolio: () => dispatch(setTab(0)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Homepage));
