/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './FundSummary.scss';
import baseUrl from 'shared/utils/baseUrl';
import CheckYellow from 'assets/icons/checkBulletYellow.png';
import CheckOn from 'assets/icons/checkOn.png';
import CheckOff from 'assets/icons/checkOff.png';
// import IconClosed from 'assets/icons/iconClosed.png';
import Card from '../Card/Card';


const FundSummary = ({
  isClosed, image, category, fundName, description, fundValue, fundButton,
}) => {
  const [isCheck, setCheck] = useState(false);
  return (
    <Card>
      <div className="fund-summary">
        <div className="fund-summary__header">
          <div className="fund-summary__header__image" style={{ backgroundImage: `url(${baseUrl}/${image})` }} />
          <div className="fund-summary__header__fund">
            <p>{category}</p>
            <h4>{fundName}</h4>
          </div>
          {isClosed
            ? (
              <div className="fund-summary__header__checkbox" onClick={() => setCheck(!isCheck)}>
                {isCheck
                  ? <img src={CheckOn} alt="" />
                  : <img src={CheckOff} alt="" />}
              </div>
            )
            : (
              <>
                {/* <img src={IconClosed} alt="" width="45" height="35" /> */}
              </>
            )}
        </div>

        <div className="fund-summary__description">
          <>{description}</>
        </div>

        <div className="fund-summary__value">
          {fundValue.map((fund, key) => (
            <ul key={key}>
              {fund.map((value, i) => (
                <li key={i}>
                  <p>{value.label}</p>
                  <div className="fund-summary__value__detail">
                    <img src={CheckYellow} alt="" />
                    <h4>{value.value}</h4>
                  </div>
                </li>
              ))}
            </ul>
          ))}
        </div>

        {fundButton
          ? (
            <div className="fund-summary__button">
              {fundButton}
            </div>
          ) : <></>}
      </div>
    </Card>
  );
};

FundSummary.propTypes = {
  /** display close icon or checkbox */
  isClosed: PropTypes.bool,
  /** Fund images  */
  image: PropTypes.string,
  /** Fund category */
  category: PropTypes.string,
  /** Fund name */
  fundName: PropTypes.string,
  /** Fund description */
  description: PropTypes.node,
  /** */
  fundValue: PropTypes.arrayOf(PropTypes.array),
  /** */
  fundButton: PropTypes.node,
};

FundSummary.defaultProps = {
  isClosed: false,
  image: '',
  category: 'Fixed Income',
  fundName: 'NW Balanced',
  description: <p>Double benefits of lower risk, big returns. Double benefits of lower risk, big returns.</p>,
  fundValue: [[
    {
      label: '3y performance',
      value: '+11.68%',
    }, {
      label: 'Min investment',
      value: 'Rp. 500 k',
    },
  ], [
    {
      label: 'Investment horizon',
      value: 'Over 5 years',
    }, {
      label: 'Risk category',
      value: 'Medium',
    },
  ]],
  fundButton: <></>,
};

export default FundSummary;
