import React, { Component, Fragment } from 'react';
import {
  Card, LayoutWithNavbar, Navbar, Button,
} from 'components';
import { PATH, STORAGE } from 'shared/constant';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import utilitiesService from 'shared/utils/utilitiesService';
import './RiskProfiling.scss';
import RiskScale from './RiskScale';
import RiskProfileResult from './RiskProfileResult';

class RiskProfiling extends Component {
  componentDidMount() {
    document.querySelector('.nw-page__body').scrollTop = 0;
  }

    onBackButton = () => {
      this.props.history.goBack();
    };

    render() {
      const {
        intl, score, profile, profileDesc,
      } = this.props;

      return (
        <LayoutWithNavbar
          header={(
            <Navbar
              title={intl.formatMessage({ id: 'riskProfile.interpretationOfExistingValues' })}
              withSideMenu
            />
                )}
        >
          <RiskScale percent={score || 67} />
          <Card className="risk-profiling-card">
            <h2>{profile ? intl.formatMessage({ id: `riskProfile.${profile}` }) : intl.formatMessage({ id: 'riskProfile.moderate' }) }</h2>
            { !profile
                    && (
                    <Fragment>
                      <div className="risk-profiling-card__date">
                        { `${intl.formatMessage({ id: 'riskProfile.assessed' }, { date: '17/10/2017' })} | ${intl.formatMessage({ id: 'riskProfile.validity' }, { date: '16/10/2019' })}` }
                      </div>
                      <div className="risk-profiling-card__warning">{ intl.formatMessage({ id: 'riskProfile.pleaseReassessYourRisk' }) }</div>
                    </Fragment>
                    )
                    }
            <p>
              { profileDesc ? intl.formatMessage({ id: utilitiesService.translateProfileDescription(profileDesc) }) : intl.formatMessage({ id: 'riskProfile.moderate_description' }) }
            </p>
          </Card>
          <Button value={intl.formatMessage({ id: 'risk.reassess' })} onButtonClick={() => this.props.history.push(PATH.RISK_QUESTIONS)} />
        </LayoutWithNavbar>
      );
    }
}

class RiskProfilingWrapper extends Component {
  render() {
    const expiredDate = localStorage.getItem(STORAGE.RISK_EXPIRE);
    // eslint-disable-next-line radix
    const isExpired = !expiredDate || moment().isAfter(parseInt(expiredDate));

    return isExpired
      ? <RiskProfiling {...this.props} />
      : <RiskProfileResult {...this.props} />;
  }
}

const mapStateToProps = state => ({
  score: state.riskProfile.score,
  profile: state.riskProfile.profile,
  profileDesc: state.riskProfile.profileDesc,
});

export default connect(mapStateToProps)(injectIntl(RiskProfilingWrapper));
