import React from 'react';
import PropTypes from 'prop-types';
import Card from '../Card/Card';
import Item from '../ItemList/Item';
import './InvestmentAnalysis.scss';

const GaugeItem = ({ label, value }) => (
  <div className="gauge-item">
    <span>{`${label}: ${value}%`}</span>
    <div className="gauge-item__value">
      <span style={{ width: `${value}%` }} />
    </div>
  </div>
);

const InvestmentAnalysis = (props) => {
  const {
    subTitle, total, current, target,
  } = props;

  return (
    <Card
      header={(
        <Item label={subTitle} isPortfolio={props.isPortfolio} color={props.color} />
    )}
    >
      <div className="investment-analysis">
        <Item label={`Total ${subTitle}`} value={total} />
        <GaugeItem label="Current" value={current} />
        <GaugeItem label="Target" value={target} />
      </div>
    </Card>
  );
};

InvestmentAnalysis.propTypes = {
  /** Investment title or type of investment */
  subTitle: PropTypes.string,
  /** Total investment */
  total: PropTypes.string,
  /** Current Percentage Investment */
  current: PropTypes.number,
  /** Target percentage Investment */
  target: PropTypes.number,
};

InvestmentAnalysis.defaultProps = {
  subTitle: 'Equities',
  total: 'Rp. 12,432,543',
  current: 28,
  target: 55,
};

export default InvestmentAnalysis;
