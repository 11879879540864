import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Card, OptionBox } from 'components';
import { injectIntl } from 'react-intl';
import './Question.scss';

const Question = ({
  category, question, answers, intl, onSelect, index, isAnwered,
}) => (
  <Card className={`question-box ${!isAnwered ? 'question-box--unanswered' : ''}`}>
    {category && <div className="question-box__category">{category}</div>}
    <h2>{question}</h2>
    <div className="question-box__answers">
      {answers && answers.map(item => (
        // turn question into radio box input name
        <OptionBox
          type="radio"
          description={intl.formatMessage({ id: item.answerDescription })}
          value={item.answerShortCode}
          name={_.camelCase(question)}
          position="left"
          key={item.answerDescription}
          onSelect={() => onSelect(item.answerShortCode, index)}
        />
      ))}
    </div>
  </Card>
);

Question.propTypes = {
  /** question category */
  category: PropTypes.string,
  /** question */
  question: PropTypes.string,
  /** possible answers */
  answers: PropTypes.arrayOf(PropTypes.object),
  /** select answer event */
  onSelect: PropTypes.func,
  /** question index (for submit answer later) */
  index: PropTypes.number,
  /** if question is answered */
  isAnwered: PropTypes.bool,
};

Question.defaultProps = {
  category: '',
  question: '',
  answers: [],
  onSelect: () => {},
  index: -1,
  isAnwered: false,
};

export default injectIntl(Question);
